@use '../styles/common/size' as waffle-size;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/mixins/breakpoints.utils' as waffle-breakpoint;

.messageBox {
  display: flex;
  border-radius: 8px;
  padding: waffle-size.$size-16;

  &.basic {
    background-color: waffle-color.$color-gray-000;
    color: waffle-color.$color-gray-700;

    .icon {
      color: waffle-color.$color-gray-600;
    }
  }

  &.primary {
    background-color: waffle-color.$color-mint-100;
    color: waffle-color.$color-mint-700;

    .icon {
      color: waffle-color.$color-mint-600;
    }
  }

  &.info {
    background-color: waffle-color.$color-blue-100;
    color: waffle-color.$color-blue-700;

    .icon {
      color: waffle-color.$color-blue-600;
    }
  }

  &.danger {
    background-color: waffle-color.$color-red-100;
    color: waffle-color.$color-red-700;

    .icon {
      color: waffle-color.$color-red-600;
    }
  }

  .icon {
    flex-shrink: 0;
    margin-top: 2px;
    margin-right: waffle-size.$size-4;
    width: 16px;
    height: 16px;
    font-size: 16px;

    &.withoutTitle {
      margin-top: 2px;
    }
  }

  &:not(.vertical) {
    .content {
      @include waffle-breakpoint.breakpoint-desktop {
        display: flex;
      }
    }

    .title {
      @include waffle-breakpoint.breakpoint-desktop {
        margin-right: waffle-size.$size-16;
        margin-bottom: 0;
        max-width: 228px;

        + .description {
          margin-left: 0;
        }
      }
    }
  }

  &.vertical {
    .title {
      margin-bottom: waffle-size.$size-8;
    }
  }

  .title {
    @include waffle-font.font(waffle-size.$size-14, 700);
    margin: 0;
    margin-bottom: waffle-size.$size-8;
    text-align: left;

    + .description {
      margin-left: -20px;
    }
  }

  .description {
    @include waffle-font.font(waffle-size.$size-12, 400);
    margin: 0;
    text-align: left;
    word-break: keep-all;
    overflow-wrap: break-word;
    color: waffle-color.$color-gray-700;

    @include waffle-breakpoint.breakpoint-desktop {
      line-height: 20px;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: flex;
        margin-bottom: waffle-size.$size-4;
        line-height: 18px;

        &::before {
          display: inline-flex;
          flex-shrink: 0;
          padding-left: 4px;
          width: 18px;
          height: 18px;
          text-align: center;
          font-size: 14px;
          content: '\2022';
        }
      }
    }
  }
}
