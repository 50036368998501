@use '../styles/common/size' as waffle-size;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/zindex' as waffle-zindex;

.tooltip {
  @include waffle-font.font(waffle-size.$size-12, 400);
  position: absolute;
  z-index: waffle-zindex.$z-8;
  border-radius: 3px;
  background-color: waffle-color.$color-gray-800;
  padding: 12px;
  max-width: calc(288px - 12px * 2);
  word-break: keep-all;
  white-space: break-spaces;
  color: waffle-color.$color-rgba-light-100;
  overflow-wrap: break-word;
}

.button {
  font-size: 14px;
  margin: 0;
  outline: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: inherit;

  &.withLabel {
    @include waffle-font.font(waffle-size.$size-14, 400);
  }

  span {
    display: inline-block;

    & + span {
      margin-left: 4px;
    }

    svg {
      display: inline-block;
      vertical-align: text-top;
    }
  }

  .label {
    max-width: calc(288px - 12px * 2);
    word-break: keep-all;
    overflow-wrap: break-word;

    &.blindText {
      position: absolute;
      margin: -1px;
      clip: rect(0 0 0 0);
      width: 1px;
      height: 1px;
      overflow: hidden;
    }
  }

  .helpIconWrap {
    vertical-align: middle;
  }

  .helpIcon {
    display: none;
  }

  &.open {
    .helpOutlineIcon {
      display: none;
    }

    .helpIcon {
      display: inline;
    }
  }
}

.hide {
  display: none;
}
