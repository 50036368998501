@use '../styles/common/size' as waffle-size;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/zindex' as waffle-zindex;

$transition-duration: 150ms;
$box-base-size: 24px;

.checkbox {
  display: block;
  position: relative;

  .icon {
    display: block;
    position: relative;
    flex-shrink: 0; // classname으로 외부에서 flex를 적용하는 경우 icon은 크기를 보장 받아야함.
    transition-property: opacity, border-color, background-color;
    transition-duration: $transition-duration;
    opacity: 1;
    z-index: waffle-zindex.$z-1;
    border: 1px solid waffle-color.$color-gray-400;
    border-radius: 2px;
    background-color: #fff;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition-property: opacity, width, fill;
      transition-duration: $transition-duration;
      fill: #fff;
    }
  }

  .input {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;

    & + .icon .checkIcon,
    & + .icon .removeIcon {
      opacity: 0;
    }

    &:checked {
      & + .icon .checkIcon {
        opacity: 1;
      }
    }

    &:indeterminate {
      & + .icon .removeIcon {
        opacity: 1;
      }

      & + .icon .checkIcon {
        opacity: 0;
      }
    }


    &:checked + .icon,
    &:indeterminate + .icon {
      border: solid 1px waffle-color.$color-mint-700;
      background-color: waffle-color.$color-mint-600;

      svg {
        fill: waffle-color.$color-rgba-light-100;
      }
    }
  }

  &.labeled {
    display: inline-flex;
    align-items: center;

    @include waffle-font.font(waffle-size.$size-14, 400);

    .icon {
      display: inline-block;
      margin-right: waffle-size.$size-8;
      vertical-align: middle;
    }

    .content {
      display: inline-block;
      color: waffle-color.$color-gray-700;
    }

    &.sm {
      .content {
        @include waffle-font.font(waffle-size.$size-14, 400);
      }
    }

    &.md {
      .content {
        @include waffle-font.font(waffle-size.$size-14, 400);
      }
    }

    &.lg {
      .content {
        @include waffle-font.font(waffle-size.$size-14, 700);
      }
    }
  }

  &.error {
    .icon {
      border-color: waffle-color.$color-red-600;
    }
  }

  &.sm {
    .icon {
      width: 16px;
      height: 16px;
    }

    .checkIcon {
      width: 10px;
      height: 10px;
    }

    .removeIcon {
      width: 8px;
      height: 1px;
    }
  }

  &.md {
    .icon {
      width: 20px;
      height: 20px;
    }

    .checkIcon {
      width: 12px;
      height: 12px;
    }

    .removeIcon {
      width: 10px;
      height: 1px;
    }
  }

  &.lg {
    .icon {
      width: 24px;
      height: 24px;
    }

    .checkIcon {
      width: 16px;
      height: 16px;
    }

    .removeIcon {
      width: 12px;
      height: 2px;
    }
  }

  &.inline {
    display: inline-block;

    &.sm {
      margin-right: waffle-size.$size-32;
    }

    &.md, &.lg {
      margin-right: waffle-size.$size-40;
    }
  }

  .input:disabled {
    + .icon {
      opacity: 0.4;
    }

    + .icon + .content {
      opacity: 0.4;
    }
  }

  @media (hover: hover) {
    &:hover {
      .input:not(:disabled) {
        + .icon {
          border-color: waffle-color.$color-gray-500;
          background-color: waffle-color.$color-gray-100;
        }
      }

      .input:checked,
      .input:indeterminate {
        + .icon {
          border-color: waffle-color.$color-mint-800;
          background-color: waffle-color.$color-mint-700;
        }
      }
    }
  }
}

.message {
  margin-top: waffle-size.$size-12;
  padding-left: 28px;

  &.sm {
    padding-left: waffle-size.$size-24;
  }

  &.md {
    padding-left: 28px;
  }

  &.lg {
    padding-left: waffle-size.$size-32;
  }
}
