@import './CommonDialog/Common.module';

$color-primary-100: #e7f9f9;
$color-primary-700: #00b2b2;
$color-gray-200: #e9ecef;
$color-gray-600: #868e96;
$color-gray-700: #495057;
$color-gray-900: #212529;
$color-rgba-dark-30:	rgb(0 0 0 / 30%);

//광고 미리보기
.previewImg {

  @mixin imgBackground {
    display: block;

    width: 100%;
    height: 100%;

    // opacity: 0;
    background: no-repeat 50%/cover border-box;
    border-radius: 8px;

    // transform: scale(1.05);
    transition: 0.25s ease-in-out;
    transition-property: opacity, transform;
  }

  padding: 32px 20px 20px;

  header {
    position: relative;

    .headerTools {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media screen and (max-width:$break-mid) {
        flex-direction: row-reverse;
      }
    }

    .dialog_close {
      display: inline-block;
    }

    .dialog_switchSize {
      position: relative;
      z-index: 10;

      display: inline-block;

      width: 120px;
      margin-bottom: 10px;

      button {
        margin-right: 5px;

        &.active {
          color: #fff;
          background: $color-primary !important;
        }
      }
    }

    .dialog_title {
      width: 100%;

      &::before {
        @include clear
      }
    }
  }

  main {
    position: relative;
    margin-top: 20px;

    .preview_content_img {
      position: relative;

      .preview_content_ad {
        position: absolute;
        z-index: 10;
        right: 8px;
        bottom: 8px;

        width: 27px;
        height: 18px;

        font-size: 10px;
        line-height: 18px;
        color: #fff;
        text-align: center;

        background-color: rgb(0 0 0 / 8%);
        border: 1px solid #fff;
        border-radius: 9px;
      }
    }

    .preview_content {
      &>p {
        @include clamp;

        display: block;
      }

      .preview_content_subCopy {
        font-size: 13px;
      }
    }
  }

  // 광고 미리보기 - 키비주얼
  &-Key {
    max-width: 1320px;

    main {
      margin-top: 40px;
    }

    .preview_content_img {
      height: 380px;

      .preview_content_background {
        @include imgBackground;
      }

      &.isMin {
        height: 206px;
      }

      @media screen and (max-width:$break-mid) {
        height: 93px;
      }
    }

    .preview_content_ad {
      z-index: 10;
      top: 16px !important;
      right: 80px !important;

      &.isMin {
        top: 8px !important;
        right: 8px !important;
      }

      @media screen and (max-width:$break-mid) {
        &:not(.isMin) {
          top: 5px !important;
          right: 24px !important;

          width: 8px !important;
          height: 5px !important;

          font-size: 0 !important;
          line-height: 5px !important;

          border-radius: 3px !important;
        }
      }
    }

    .preview_content {
      position: absolute;
      right: 0;
      bottom: 40px;
      left: 80px;

      color: #fff;

      &.isMin {
        bottom: 8px !important;
        left: 14px !important;
      }

      &>p {
        -webkit-line-clamp: 2;
      }

      .preview_content_lossText {
        margin-bottom: 8px;

        font-size: 12px !important;
        font-weight: 400;
        line-height: 16px;
        color: hsl(0deg 0% 100% / 68%);
      }

      @media screen and (max-width:$break-mid) {
        &:not(.isMin) {
          bottom: 8px;
          left: 21px;
        }
      }
    }
  }

  // 광고 미리보기 - 프로젝트
  &-project {

    @mixin project {
      &.isMain {
        padding: 32px 20px 20px;

        main {
          .preview_content_img {
            width: 100%;
            height: 179px;
          }
        }
      }

      &:not(.isMain) {
        main {
          &::after {
            @include clear
          }

          .preview_content_img {
            float: left;
            width: 150px;
            height: 100px;
            margin-bottom: 0;

            img {
              border-radius: 0;
            }
          }

          .preview_content_mainCopy {
            @include font15;

            max-height: 40px;
            margin-bottom: 0;
            line-height: 20px;

            strong {
              font-weight: 500;
            }
          }

          .preview_content {
            float: left;
            width: calc(100% - 150px);
            padding: 0 0 0 16px;

            .preview_content_subInfo {
              font-size: 12px;

              span {
                font-weight: 500;
                color: #90949c;
              }
            }
          }
        }
      }

      &.isRwHome {
        .preview_fixed {
          position: relative;

          article {
            height: 80px;
          }

          .preview_content_processing {
            position: absolute;
            inset: 72px 0 0;

            width: 100%;
            height: 20px;
            margin: auto;

            em {
              font-size: 12px;

              &:last-child {
                float: right;
              }
            }
          }
        }
      }
    }

    .preview_fixed {
      display: none;
    }

    article {
      height: 80px;
    }

    &.isMain {
      max-width: 443px;

      main {
        .preview_content_img {
          width: 403px;
          height: 222px;

          .preview_content_background {
            @include imgBackground;
          }
        }

        .preview_content {
          position: relative;

          max-height: 100%;
          padding: 16px;

          border: solid #e6eaed;
          border-width: 0 1px 1px;
          border-radius: 0 0 2px 2px;

          .preview_content_mainCopy {
            @include font15;
          }

          .preview_content_subCopy {
            font-weight: 400;
            color: $color-grey;
          }

          button {
            position: absolute;
            top: 20px;
            right: 20px;
          }
        }
      }
    }

    &:not(.isMain) {
      max-width: 352px;

      main {
        .preview_content_img {
          height: 176px;
          margin-bottom: 10px;

          .preview_content_background {
            @include imgBackground;

            border-radius: 3px;
          }
        }

        .preview_content_mainCopy {
          max-height: 48px;
          margin-bottom: 4px;

          font-size: 17px;
          line-height: 24px;

          -webkit-line-clamp: 2;

          strong {
            font-weight: 500;
          }
        }

        .preview_fixed {
          .preview_content_subInfo {
            height: 16px;

            font-size: 13px;
            font-weight: 500;
            line-height: 100%;
            color: $color-grey;

            span {
              font-size: 13px !important;
              font-weight: 500;
              color: #90949c;

              &::before {
                content: "|";
                margin: 0 5px;
                font-weight: normal;
                color: #dadce0;
              }

              &:first-child::before {
                content: none;
                margin: 0;
              }
            }
          }

          .preview_content_processing {
            display: flex;
            flex-direction: row;
            width: 100%;

            .preview_content_percent {
              font-size: 17px;
              color: #00b2b2;
            }

            em {
              font-size: 14px;
              font-weight: 500;
              color: $color-grey;
            }

            .preview_content_dayLeft {
              flex: 1;
            }

            section {
              display: flex;
              justify-content: space-between;
            }
          }

          &.fixed_rw {
            position: relative;

            .preview_content_subInfo {
              margin: 0 0 12px;
            }

            .preview_content_processing {
              top: auto;
              bottom: auto;
              display: block !important;

              .preview_content_gauge {
                overflow: hidden;
                width: 100%;
                height: 3px;
                background-color: #e6eaed;

                span {
                  display: block;
                  width: 50%;
                  height: 3px;
                  background-color: #00b2b2;
                }
              }

              .preview_content_amount {
                &::before {
                  content: "·";
                  padding: 0 2px;
                }
              }

              .preview_content_dayLeft {
                text-align: right;
              }
            }
          }

          &.fixed_invest {
            &.preview_content_cat {
              font-size: 12px;
              line-height: 100%;
              color: $color-grey;

              .preview_content_opening {
                display: inline-block;

                height: 18px;
                margin-right: 4px;
                padding: 1px 4px 0;

                font-size: 10px;
                font-weight: 500;
                line-height: 18px;
                color: #fff;

                background-color: #00b2b2;
                border-radius: 4px;
              }

              em {
                font-weight: 500;
                color: #1d2129;

                &::after {
                  content: "|";
                  overflow: hidden;
                  margin: 0 4px;
                  color: #e6eaed;
                }
              }
            }

            .preview_content_processing {
              overflow: hidden;
              height: 22px;
              margin-bottom: 4px;

              .preview_content_increase {
                display: inline-block;

                min-width: 46px;
                height: 18px;
                margin: 0 8px;
                padding: 4px 4px 0;

                font-size: 10px;
                font-weight: 500;
                line-height: 100%;
                color: $color-grey;

                border: 1px solid $color-grey;
                border-radius: 1em;
              }
            }

            .preview_content_dayLeft {
              &::before {
                content: "|";

                margin: 0 5px;

                font-weight: normal;
                color: #dadce0;
                vertical-align: sup;
              }
            }
          }
        }

        .fixed_coming {
          em {
            display: block;

            height: 24px;

            font-size: 17px;
            font-weight: 500;
            color: #00b2b2;
          }
        }

        .preview_content_subCopy,
        button {
          display: none !important;
        }
      }
    }

    &.isRwHome {
      .preview_fixed.fixed_rw {
        display: block;
      }
    }

    &.isInvestHome {
      .preview_fixed.fixed_invest {
        display: block;
      }
    }

    &.isComing {
      .preview_fixed.fixed_coming {
        display: block;
      }
    }

    &.isMin {
      @include project
    }
  }

  // 띠배너
  &-line {

    @mixin line {
      .preview_content_img {
        height: 68px;
      }
    }

    main {

      @mixin copyStyle {
        overflow: hidden;

        width: 187px;

        font-size: 15px !important;
        color: rgb(0 0 0 / 84%);
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .preview_content_background {
        position: absolute;
        inset: 0;

        box-sizing: border-box;
        width: 304px;
        height: 100%;
        margin: 0 auto;
        padding: 15px 14px 0 9px;

        background: 100% 50%/110px 72px no-repeat;
      }

      .preview_content_img {
        width: 100%;
        height: 72px;
        padding: 0 17px;

        text-align: right;

        border: 1px solid rgb(0 0 0 / 6%);
        border-radius: 8px;

        img {
          display: inline-block;
          width: 72px;
        }

        &.isMin {
          border-radius: 3px;
        }
      }

      .preview_content {
        position: absolute;
        inset: 0;

        max-width: 304px;
        height: 44px;
        margin: 15px auto;
      }

      .preview_content_subCopy {
        @include copyStyle;

        height: 22px;
      }

      .preview_content_mainCopy {
        @include copyStyle;

        height: 17px;
        padding-top: 2px;
        font-weight: 700;
        line-height: 1;
      }
    }

    &.isMain {
      max-width: 624px;

      main {
        border-radius: 3px;
      }
    }

    &.isMy {
      max-width: 1200px;
    }

    &.isMin {
      @include line
    }

    @media screen and (max-width:$break-mid) {
      main {
        @include line
      ;

        margin-top: 40px;}
    }
  }

  // 광고 미리보기 - 리스트
  &-store {
    padding: 32px 20px !important;

    &.isList {
      max-width: 307px;

      &.isMin {
        max-width: 207px;
      }
    }

    main {
      margin-top: 24px;
    }

    .preview_content_img {
      width: 267px;
      height: 267px;

      .preview_content_background {
        @include imgBackground;
      }

      &.isMin {
        width: 167px;
        height: 167px;
      }

      @media screen and (max-width:$break-mid) {
        height: 93px;
      }
    }

    .preview_content {
      position: relative;
      padding-top: 8px;

      .fundingSucceeded {
        width: fit-content;
        height: 18px;
        padding: 0 12px;

        font-size: 10px;
        line-height: 18px;
        color: $color-primary-700;

        background-color: $color-primary-100;
        border-radius: 9px;
      }

      .title {
        padding-top: 4px;
        font-size: 16px;
        color: $color-gray-900;
      }

      .maker {
        padding-top: 4px;
        font-size: 12px;
        color: $color-rgba-dark-30;
      }

      .amount {
        padding-top: 6px;
        font-size: 18px;
        color: $color-gray-700;

        em {
          font-weight: bolder;
          color: $color-primary-700;
        }
      }

      .account {
        position: absolute;
        right: 0;
        bottom: 10px;

        display: flex;
        flex-direction: row;

        width: fit-content;
        height: 18px;
        padding: 1px 8px;

        font-size: 10px;
        line-height: 14px;
        color: $color-gray-600;

        border: 1px solid $color-gray-200;
        border-radius: 9px;

        svg {
          margin-top: 2px;
        }
      }

      &.isMin {
        .account {
          position: relative;
          margin-top: 12px;
        }
      }
    }
  }

  &-Main {
    max-width: calc(375px + 48px);
    padding: 32px 24px;

    main {
      .preview_content_background {
        display: none;
      }

      .preview_content_img {
        width: 343px;
        height: 100px;
        padding: 0 17px;

        text-align: right;

        border-radius: 8px;

        img {
          display: block;
          width: 121px;
        }
      }

      .preview_content {
        position: absolute;
        inset: 0;

        max-width: 343px;
        height: 100px;
        margin: 15px auto;
        padding: 22px 24px;

        border: 1px solid rgb(0 0 0 / 6%);
        border-radius: 8px;

        img {
          position: absolute;
          top: 0;
          right: 8px;
        }
      }

      .preview_content_copy {
        overflow: hidden;

        font-size: 16px;
        font-weight: bold;
        font-style: normal;
        line-height: 28px;
        color: #212529;
        text-overflow: ellipsis;
        letter-spacing: -0.3px;
        white-space: nowrap;
      }
    }

    @media screen and (max-width:$break-mid) {
      main {
        margin-top: 40px;
      }
    }
  }

  &.isMin {
    max-width: calc(375px + 48px);
    padding: 32px 24px;
  }

  @media screen and (max-width:$break-mid) {
    padding: 32px 24px 20px;

    header {
      h3 {
        margin-top: 12px;
      }
    }
  }
}


.keyBannerContent {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background-image: linear-gradient(180deg, rgb(0 0 0 / 8%), rgb(0 0 0 / 64%));
  }

  .keyBannerNotify {
    margin-top: 16px;
    margin-left: 16px;
    font-size: 15px;
    color: $color-grey;
  }
}

.keyBannerCopyContainer {
  &.isMin {
    margin-bottom: 8px;
  }

  @media screen and (max-width:$break-mid) {
    margin-bottom: 2px;
  }
}

.keyBannerMainCopy {
  display: block;

  width: 460px;
  margin-bottom: 8px;

  font-size: 32px;
  font-weight: 700;
  line-height: 40px !important;
  color: #fff;
  letter-spacing: -0.7px;
  word-break: break-all;

  &.isMin {
    width: 280px;
    font-size: 21px;
    font-weight: 500;
    line-height: 28px !important;
  }

  @media screen and (max-width:$break-mid) {
    &:not(.isMin) {
      width: 40px;
      height: 8px;
      margin-bottom: 2px;

      font-size: 0;
      line-height: 0 !important;
      letter-spacing: -0.7px;

      background-color: lightgray;
    }
  }
}

.keyBannerSubCopy {
  display: inline-block;

  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  color: #fff;

  &.isMin {
    line-height: 13px;
  }

  @media screen and (max-width:$break-mid) {
    &:not(.isMin) {
      display: block;

      width: 30px;
      height: 5px;

      font-size: 0;
      line-height: 0;

      background-color: gray;
    }
  }
}

.keyBannerActiveBar {
  position: absolute;
  z-index: 9;
  bottom: 20px;
  left: 80px;

  box-sizing: border-box !important;
  width: 375px;
  height: 2px;

  background: hsl(0deg 0% 100% / 30%);

  &.isMin {
    bottom: 8px;
    left: 16px;
    width: 125px;
  }

  div {
    width: 30%;
    height: 100%;
    background: #fff;
    transition: width 0.2s;
  }

  @media screen and (max-width:$break-mid) {
    &:not(.isMin) {
      bottom: 6px;
      left: 21px;
      width: 111px;
      height: 1px;
    }
  }
}
