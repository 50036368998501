@use '@common/waffle-styled/color';

.leftSide {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 224px);
  border-right: 1px solid color.$color-gray-200;
}

.title {
  margin-bottom: 0.5 em;
  padding: 32px 26px;

  font-size: 19px;
  font-weight: 700;
  line-height: 100%;
  color: #fff;

  background-color: color.$color-mint-600;
}

.contentContainer {
  background-color: #fff;
}

.footerContainer {
  padding: 0;
  background-color: #fff;
}
