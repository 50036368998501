.firstViewContainer {
  position: relative;
  padding: 0;
  background-color: #cdd3d8;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.normalContainer {
  position: relative;
  padding: 0;
  background-color: #cdd3d8;
  border-radius: 8px;
  margin: 2px;
  width: 440px;
  height: 280px;
  margin: 4px auto;
}

.largeContainer {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;

  height: 500px;
}

.desktopContainer {
  position: relative;
  padding: 0;
  background-color: #cdd3d8;
  border-radius: 8px;
  margin: 2px;
  width: 546px;
  height: 350px;
}
.mobileContainer {
  position: relative;
  padding: 0;
  background-color: #cdd3d8;
  border-radius: 8px;
  margin: 2px;
  width: 398px;
  height: 432px;
}

.tag {
  position: absolute;
  bottom: -30px;
  left: 50%;
  transition: translate(-50%);
}
