.fieldContainer {
  margin: 16px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  input {
    flex-grow: 2;
    margin-right: 8px;
  }
  button {
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 114px;
    font-size: 14px;
  }
}

.errMessage {
  display: block;
  color: #ff8181;
  font-size: 13px;
  min-height: 12px;
  margin-bottom: 4px;
}
