// packages/wadiz/waffle/packages/waffle/src/Popper/Popper.module.scss
$color-rgba-light-100: #fff;
$spacing-3: 8px;
$spacing-4: 12px;
$color-gray-700: #495057;

@mixin caption-1-regular {
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0;
}

.container {
  position: relative;
  top: -48px;
}

.popper {
  @include caption-1-regular;

  position: absolute;
  top: 0;
  right: 0;

  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  padding: $spacing-3 $spacing-4 $spacing-3 $spacing-4;

  font-weight: 400;
  color: $color-gray-700;
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: break-spaces;

  background: $color-rgba-light-100;
  border: 1px solid #e9ecef;
  border-radius: 3px;

  .arrow,
  .arrow::before {
    position: absolute;
    width: 9px;
    height: 9px;
    background: inherit;
  }

  .arrows {
    position: absolute;
    right: 0;
    bottom: -4px;
    transform: translateX(-38.9922px);

    width: 9px;
    height: 9px;

    &::before {
      content: '';

      position: absolute;
      bottom: -2px;
      transform: rotate(45deg);

      width: 9px;
      height: 9px;

      background: #fff;
      border-right: 1px solid #e9ecef;
      border-bottom: 1px solid #e9ecef;
    }
  }
}
