// 미디어 사이즈
$break-min: 375px;
$break-small: 540px;
$break-mid: 768px;
$break-large: 1064px;
$break-max: 1440px;

// 색상
$color-grey:#90949c;
$color-slate:#60656a;
$color-primary:#00c4c4;

// 폰트 사이즈 
@mixin font13 {
  font-size: 13px;
}

@mixin font15 {
  font-size: 15px;
}

@mixin font17 {
  font-size: 17px;
}

@mixin font19 {
  font-size: 19px;
}

@mixin font21 {
  font-size: 21px;
}

// 공통
@mixin clear {
  display: block;
  clear: both;
  content: "";
}

@mixin clamp {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
}

@mixin absoluteCenter {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.scrollbar::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #e4e7e5;
  border-radius: 2px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.toast_toast {
  width: auto !important;
  min-width: 350px !important;
  right: 0 !important;

  @media screen and (max-width:$break-mid) {
    width: 100% !important;
  }
}

.toast_back {
  background: rgba(0, 0, 0, .9) !important;
  padding: 15px !important;
  border-radius: 4px !important;

  @media screen and (max-width:$break-mid) {
    width: 100vw;
  }
}

.toast_body {
  font-size: 18px !important;
  color: #fff !important;
}

.toast_progress {
  opacity: 0 !important;
}


$width-full:100%;

.isMo {
  display: none;
}

*::before {
  box-sizing: border-box;
}

button {
  outline: none;
}

.component_container {
  min-height: 500px;
}

.main_container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;

  @media screen and (max-width:$break-mid) {
    display: block;
  }

  .content_wrap {
    position: relative;
    width: calc(100% - 240px);
    max-width: 1200px;
    padding: 33px 60px 50px 66px;

    @media screen and (max-width:$break-mid) {
      padding: 30px 16px;
      width: 100%;
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }

    hgroup {
      p {
        color: #777c81;
        font-size: 17px;
        min-height: 24px;
        margin-top: 10px;
        display: inline-flex;
        align-items: center;
        width: 100%;
        flex-grow: 1;

        a {
          display: contents;
        }
      }
    }

    h2 {
      font-size: 36px;
      line-height: 125%;
      min-height: 44px;
      display: flex;
      width: 100%;
      align-items: center;

      @media screen and (max-width:$break-mid) {
        font-size: 24px;
        min-height: 36px;
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

label.rpDropdown {
  padding: 0 32px 0 0 !important;

  &>select {
    padding: 0 0 0 16px !important;
    width: 100%;
  }
}

@media screen and (max-width:$break-mid) {
  .isMo {
    display: block;
  }

  .isDeskTop {
    display: none !important;
  }
}