@import '../AdModifyContent.module.scss';

.process_title {
  margin: 40px 0 8px;
  font-family: NotoSansKR;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: -0.3px;
  color: #212529;
}

.modify_cont {
  margin-top: 4px;
  font-family: NotoSansKR;
  font-size: 17px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.41;
  letter-spacing: normal;
  color: #8e9298;
}

.point_red_bottom {
  margin-bottom: 40px !important;
}
