.terms {
  margin-left: 8px;
  font-size: 12px;
  text-decoration: underline;
  color: #00b2b2;
  cursor: pointer;
}

.heading {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 32px;
}

.contentHeading {
  font-size: 16px;
  margin-bottom: 8px;
}

.container {
  margin: 72px 116px;
}

.contentContainer {
  width: 640px;
  height: 180px;
  border: 1px solid #f2f4f6;
  overflow-y: scroll;
  padding: 12px;
  color: #adb5bd;
  h2 {
    color: #adb5bd;
  }

  article ol > li {
    margin-left: 17px;
    list-style: decimal outside;
    ol > li {
      list-style-type: lower-alpha;
    }
  }

  article li {
    margin-bottom: 6px;
    line-height: 24px;
    word-break: keep-all;
  }
}

.buttonContainer {
  position: relative;
  margin-top: 32px;
  height: 48px;
  button {
    position: relative;
    padding-top: 14px !important;
  }
}

.antModal {
  span[class~='ant-modal-close-icon'] {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  button[class~='ant-modal-close'] {
    width: 100px;
    height: 100px;
  }
}
