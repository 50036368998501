@use "sass:map";
@use '../variables/breakpoints';

$media-other-min-width: 960px;

@mixin media-only-mobile($min-width: $media-other-min-width) {
  @media screen and (max-width: ($min-width - 1)) {
    @content;
  }
}

@mixin media-other($min-width: $media-other-min-width) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

// @mixin device-retina {
//   @media screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
//     @content;
//   }
// }

// @mixin pixel-ratio($ratio: 2) {
//   @media screen and (-webkit-min-device-pixel-ratio: $ratio), (min-resolution: #{$ratio}dppx) {
//     @content;
//   }
// }

// breakpoint-mobile-small은 360미만의 해상도에서 필요한 스타일을 보여주기 위해 사용합니다.
// 최대 사이즈가 359px일 경우 동작합니다.
@mixin breakpoint-mobile-small {
  @media screen and (max-width: map.get(breakpoints.$breakpoints-map, mobile)) {
    @content;
  }
}

// breakpoint-desktop은 mobile과 desktop 해상도의 분기를 위해 사용합니다.
// 최소사이즈가 1096px일 경우 동작합니다.
@mixin breakpoint-desktop {
  @media screen and (min-width: map.get(breakpoints.$breakpoints-map, desktop)) {
    @content;
  }
}


// [start] 여기서부터는 FE-6703 close 시점에서 제거 예정입니다.
// Breakpoints
@mixin breakpoint($min-width: map.get(breakpoints.$breakpoints-map, medium)) {
  @media screen and (min-width: $min-width) {
    @content;
  }
}

@mixin breakpoint-xsmall {
  @media screen and (min-width: (map.get(breakpoints.$breakpoints-map, xsmall) + 1px)) {
    @content;
  }
}

@mixin breakpoint-small {
  @media screen and (min-width: (map.get(breakpoints.$breakpoints-map, small) + 1px)) {
    @content;
  }
}

@mixin breakpoint-medium {
  @media screen and (min-width: map.get(breakpoints.$breakpoints-map, medium)) {
    @content;
  }
}

@mixin breakpoint-large {
  @media screen and (min-width: map.get(breakpoints.$breakpoints-map, large)) {
    @content;
  }
}

// [end] 여기까지 FE-6703 close 시점에서 제거 예정입니다.

@mixin breakpoint-other {
  $max-width: (map.get(breakpoints.$breakpoints-map, large) + 1px);

  @media screen and (max-width: $max-width) {
    @content;
  }
}

@mixin breakpoint-legacy {
  @media screen and (width >= 960px) {
    @content;
  }
}
