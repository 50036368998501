.guide_wrap {
  position: relative;
  padding: 0 0 8px;

  &:after {
    display: block;
    clear: both;
    content: '';
  }

  & > label {
    float: left;
    padding-right: 5px;
    font-size: 17px;
    font-weight: normal;
  }
}
.search_keyword_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    flex-grow: 2;
    margin-right: 8px;
  }
  button {
    flex-grow: 1;
    box-sizing: border-box;
    max-width: 114px;
    font-size: 14px;
  }
}
