@use '../styles/common/color' as waffle-color;
@use 'Button.mixin' as bm;

$prefix: '' !default;
$transition-duration: 150ms;

.loader {
  position: absolute;
  inset: 0;
  padding: 0.5em;

  > .svg {
    width: 100%;
    height: 100%;
  }
}

#{$prefix}.button {
  // Hover, Active
  @include bm.button-active {
    color: waffle-color.$color-gray-800;
    background-color: waffle-color.$color-gray-100;
    border-color: waffle-color.$color-gray-500;
  }

  cursor: pointer;

  position: relative;

  height: 48px;
  padding: 9px 23px;

  font-size: 16px;
  color: waffle-color.$color-gray-700;
  text-decoration: none;
  white-space: nowrap;
  vertical-align: middle;

  appearance: none;
  background-color: transparent;
  border: 1px solid waffle-color.$color-gray-400;
  border-radius: 4px;

  transition-duration: $transition-duration;
  transition-property: background-color, border-color, color, opacity;


  span {
    display: inline-flex;
    align-items: center;
    vertical-align: top;

    svg {
      display: inline-block;
    }
  }

  // Disabled
  &:not(.loading) {
    @include bm.button-disabled;
  }

  // Circular
  &.circular {
    border-radius: 999em;
  }

  // Full Width
  &.block {
    width: 100%;
  }

  // Loading
  &.loading {
    pointer-events: none;
    cursor: default;

    > span {
      opacity: 0;
    }

    .loader {
      opacity: 1;
    }

    &.iconOnly {
      > span {
        svg {
          opacity: 0;
        }
      }
    }

    @media (hover: hover) {
      &.primary:hover {
        .loader {
          circle {
            stroke: #fff !important;
          }
        }
      }
    }
  }

  // Contained Button
  // ----------------
  &.contained {

    @include bm.button-active {
      color: waffle-color.$color-rgba-light-100;
      background-color: waffle-color.$color-gray-800;
      border-color: waffle-color.$color-gray-800;
    }

    color: waffle-color.$color-rgba-light-100;
    background-color: waffle-color.$color-gray-700;
    border-color: waffle-color.$color-gray-700;
  }


  // Primary Color
  // -------------
  &.primary {

    @include bm.button-active {
      color: waffle-color.$color-mint-700;
      background-color: waffle-color.$color-mint-100;
      border-color: waffle-color.$color-mint-600;
    }

    color: waffle-color.$color-mint-600;
    border-color: waffle-color.$color-mint-600;

    &.contained {

      @include bm.button-active {
        color: waffle-color.$color-rgba-light-100;
        background-color: waffle-color.$color-mint-700;
        border-color: waffle-color.$color-mint-700;
      }

      color: waffle-color.$color-rgba-light-100;
      background-color: waffle-color.$color-mint-600;
      border-color: waffle-color.$color-mint-600;
    }
  }

  // Secondary Color
  // ---------------
  &.secondary {

    @include bm.button-active {
      color: waffle-color.$color-gray-800;
      background-color: waffle-color.$color-gray-100;
      border-color: waffle-color.$color-gray-500;
    }

    color: waffle-color.$color-gray-700;
    border-color: waffle-color.$color-gray-400;

    &.contained {

      @include bm.button-active {
        color: waffle-color.$color-rgba-light-100;
        background-color: waffle-color.$color-gray-800;
        border-color: waffle-color.$color-gray-800;
      }

      color: waffle-color.$color-rgba-light-100;
      background-color: waffle-color.$color-gray-700;
      border-color: waffle-color.$color-gray-700;
    }
  }

  // TertiaryMint
  // ---------------
  &.tertiaryMint {

    @include bm.button-active {
      color: waffle-color.$color-mint-700;
      background-color: waffle-color.$color-mint-200;
      border-color: waffle-color.$color-mint-200;
    }

    color: waffle-color.$color-mint-600;
    border-color: waffle-color.$color-mint-100;

    &.contained {
      color: waffle-color.$color-mint-600;
      background-color: waffle-color.$color-mint-100;
      border-color: waffle-color.$color-mint-100;
    }
  }

  // TertiaryGrey
  // ---------------
  &.tertiaryGrey {

    @include bm.button-active {
      color: waffle-color.$color-gray-800;
      background-color: waffle-color.$color-gray-200;
      border-color: waffle-color.$color-gray-200;
    }

    color: waffle-color.$color-gray-700;
    border-color: waffle-color.$color-gray-100;

    &.contained {
      color: waffle-color.$color-gray-700;
      background-color: waffle-color.$color-gray-100;
      border-color: waffle-color.$color-gray-100;
    }
  }

  // Info Color
  // ----------
  &.info {

    @include bm.button-active {
      color: waffle-color.$color-blue-700;
      background-color: waffle-color.$color-blue-100;
      border-color: waffle-color.$color-blue-700;
    }

    color: waffle-color.$color-blue-600;
    border-color: waffle-color.$color-blue-600;

    &.contained {

      @include bm.button-active {
        color: waffle-color.$color-rgba-light-100;
        background-color: waffle-color.$color-blue-700;
        border-color: waffle-color.$color-blue-700;
      }

      color: waffle-color.$color-rgba-light-100;
      background-color: waffle-color.$color-blue-600;
      border-color: waffle-color.$color-blue-600;
    }
  }

  // Danger Color
  // ------------
  &.danger {

    @include bm.button-active {
      color: waffle-color.$color-red-700;
      background-color: waffle-color.$color-red-100;
      border-color: waffle-color.$color-red-700;
    }

    color: waffle-color.$color-red-600;
    border-color: waffle-color.$color-red-600;

    &.contained {

      @include bm.button-active {
        color: waffle-color.$color-rgba-light-100;
        background-color: waffle-color.$color-red-700;
        border-color: waffle-color.$color-red-700;
      }

      color: waffle-color.$color-rgba-light-100;
      background-color: waffle-color.$color-red-600;
      border-color: waffle-color.$color-red-600;
    }
  }

  // Icon
  // ----
  svg.icon {
    width: 16px;
    height: 16px;

    &:first-child {
      margin-right: 6px;
    }

    &:last-child {
      margin-left: 6px;
    }
  }

  .children {
    vertical-align: top;
  }

  // size
  // ----
  &.xs {
    height: 28px;
    padding: 4px 11px;
    font-size: 12px;

    svg.icon {
      @include bm.xs-icon;

      &:first-child {
        margin-right: 4px;
      }

      &:last-child {
        margin-left: 4px;
      }
    }

    &.startIcon {
      padding-left: 8px;
    }

    &.endIcon {
      padding-right: 8px;
    }
  }

  &.sm {
    height: 32px;
    padding: 6px 11px;
    font-size: 14px;

    svg.icon {
      @include bm.sm-icon;

      &:first-child {
        margin-right: 4px;
      }

      &:last-child {
        margin-left: 4px;
      }
    }
  }

  &.md {
    height: 40px;
    padding: 9px 15px;
    font-size: 14px;

    svg.icon {
      @include bm.md-icon;

      &:first-child {
        margin-right: 6px;
      }

      &:last-child {
        margin-left: 6px;
      }
    }
  }

  &.lg {
    height: 48px;
    padding: 9px 23px;
    font-size: 16px;

    svg.icon {
      @include bm.lg-icon;

      &:first-child {
        margin-right: 6px;
      }

      &:last-child {
        margin-left: 6px;
      }
    }
  }

  &.xl {
    height: 56px;
    padding: 9px 27px;
    font-size: 18px;

    svg.icon {
      @include bm.xl-icon;

      &:first-child {
        margin-right: 8px;
      }

      &:last-child {
        margin-left: 8px;
      }
    }
  }

  /* stylelint-disable-next-line selector-class-pattern */
  &.inline-block {
    display: inline-block;

    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    line-height: 1.25;
  }

  // iconOnly일 경우에는 아이콘 버튼이지만, 패딩, 사이즈가 없음.
  &.iconOnly {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: auto;
    height: auto;
    padding: 0;

    line-height: 1;

    & > span {
      svg {
        @include bm.md-icon;
      }
    }

    &.xs {
      width: 28px;
      height: 28px;

      & > span {
        svg {
          @include bm.xs-icon;
        }
      }
    }

    &.sm {
      width: 32px;
      height: 32px;

      & > span {
        svg {
          @include bm.sm-icon;
        }
      }
    }

    &.md {
      width: 40px;
      height: 40px;

      & > span {
        svg {
          @include bm.md-icon;
        }
      }
    }

    &.lg {
      width: 48px;
      height: 48px;

      & > span {
        svg {
          @include bm.lg-icon;
        }
      }
    }

    &.xl {
      width: 56px;
      height: 56px;

      & > span {
        svg {
          @include bm.xl-icon;
        }
      }
    }
  }

  // Text
  // ----
  &.text, &.textLink {
    &.xs,
    &.sm,
    &.md,
    &.lg,
    &.xl,
    &.primary,
    &.secondary,
    &.tertiaryMint,
    &.tertiaryGrey,
    &.info,
    &.danger {

      @include bm.button-active {
        background-color: transparent;
      }

      height: auto;
      padding: 0;
      border: 0;
      border-color: transparent;
    }
  }

  &.textLink {
    line-height: 1.5;

    &::before {
      content: '';

      position: absolute;
      right: 0;
      bottom: -1px;
      left: 0;

      height: 1px;
    }

    &.primary {
      &::before {
        background-color: waffle-color.$color-mint-600;
      }
    }

    &.secondary {
      &::before {
        background-color: waffle-color.$color-gray-700;
      }
    }

    &.info {
      &::before {
        background-color: waffle-color.$color-blue-600;
      }
    }

    &.danger {
      &::before {
        background-color: waffle-color.$color-red-600;
      }
    }
  }

  @at-root a#{&} {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;

    &.block {
      text-align: center;
    }
  }
}
