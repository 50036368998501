@use '@common/waffle-styled/font';
@use '@common/waffle-styled/color';

.screen {
  display: flex;
  gap: 8px;
  align-items: center;

  width: 340px;
  max-width: 340px;
  margin: 0;
  padding: 12px;

  background-color: #f4f4f4;
  backdrop-filter: blur(17.3px);
  border-radius: 16px;
}

.content {
  position: relative;
  flex: 1;
}

.wadizIcon {
  display: block;
  width: 36px;
  border-radius: 8px;
}

.mainCopy {
  @include font.font(14px, 700);

  margin: 0;
  color: color.$color-gray-900;
}

.subCopy {
  @include font.font(12px, 400);

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  margin: 4px 0 0;

  color: color.$color-gray-700;
  text-overflow: ellipsis;

  -webkit-line-clamp: 2;
}

.date {
  @include font.font(12px, 500);

  position: absolute;
  top: 0;
  right: 0;
  color: color.$color-gray-500;
}
