@use '../styles/common/mixins/breakpoints.utils' as waffle-breakpoint;
@use '../styles/common/zindex' as waffle-zindex;

.container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: waffle-zindex.$z-40;
  width: 100%;
  pointer-events: none;
}

@include waffle-breakpoint.breakpoint-desktop {
  .container {
    top: auto;
    bottom: 0;
    align-items: flex-end;
  }
}
