/**
 * Color
 */

// Semantic color - Info
$color-blue-100: #eff3fd;
$color-blue-200: #d0dbfb;
$color-blue-300: #b1c3f9;
$color-blue-400: #99b2ff;
$color-blue-500: #7396ff;
$color-blue-600: #557cf2;
$color-blue-700: #4167d9;
$color-blue-800: #395bbf;

// Gray
$color-gray-000: #f9fafb;
$color-gray-100: #f2f4f6;
$color-gray-200: #e9ecef;
$color-gray-300: #dde2e6;
$color-gray-400: #cdd3d8;
$color-gray-500: #adb5bd;
$color-gray-600: #868e96;
$color-gray-700: #495057;
$color-gray-800: #343a40;
$color-gray-900: #212529;

// Brand Color - Primary
$color-mint-100: #e7f9f9;
$color-mint-200: #b9eeee;
$color-mint-300: #8be4e4;
$color-mint-400: #5cdede;
$color-mint-500: #2ed5d5;
$color-mint-600: #00c4c4;
$color-mint-700: #00b2b2;
$color-mint-800: #00a2a2;

// Semantic color - Danger
$color-red-100: #fff1f1;
$color-red-200: #ffd5d5;
$color-red-300: #ffb9b9;
$color-red-400: #ff9d9d;
$color-red-500: #ff8181;
$color-red-600: #f66;
$color-red-700: #f25555;
$color-red-800: #e54545;

// RGB Dark
$color-rgba-dark-30:	rgb(0 0 0 / 30%);
$color-rgba-dark-54: rgb(0 0 0 / 54%);
$color-rgba-dark-68: rgb(0 0 0 / 68%);
$color-rgba-dark-100: #000;

// RGB White
$color-rgba-light-68: rgb(255 255 255 / 68%);
$color-rgba-light-84: rgb(255 255 255 / 84%);
$color-rgba-light-100: #fff;

// Yellow
$color-yellow-100: #fff8e7;
$color-yellow-200: #ffeab9;
$color-yellow-300: #ffdd8b;
$color-yellow-400: #ffcc4c;
$color-yellow-500: #ffbe1a;
$color-yellow-600: #ffad15;
$color-yellow-700: #faa60a;
$color-yellow-800: #f69301;
