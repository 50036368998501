@use '../styles/common/size' as waffle-size;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/color' as waffle-color;

.helperMessage {
  @include waffle-font.font(waffle-size.$size-12, 400);
  display: block;
  margin-top: 4px;
  color: waffle-color.$color-gray-600;
  font-style: normal;

  &.error {
    color: waffle-color.$color-red-800;
  }

  svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: -1px;
  }
}
