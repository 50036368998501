@import '../../containers/MainContainer/MainContainer.module.scss';

.account_manage_help {

  p {
    border: solid 1px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.54);
    position: relative;
    padding: 17px 17px 21px 57px;
    margin: 32px 0 60px;
    font-size: 13px;

    @media screen and (max-width:$break-mid) {
      padding: 16px 16px 16px 34px;
    }

    i {
      position: absolute;
      left: 40px;
      @media screen and (max-width:$break-mid) {
        left: 17px;
      }
    }
  }

  section {
    margin: 0 0 60px;
    border-bottom: 1px solid #e6eaed;
    font-size: 13px;

    h3 {
      font-weight: normal;
      font-size: 17px;
      margin: 0 0 10px;
    }
  }

  dl {
    width: 100%;
    padding: 28px 37px;
    border: solid #e6eaed;
    border-width: 1px 1px 0;

    @media screen and (max-width:$break-mid) {
      padding: 28px 16px;
    }

    dt {
      cursor: pointer;
      font-size: 18px;
    }

    dd {
      margin: 19px 0 0;
      @include font15;
      color: #949592;
      font-weight: 500;
      position: relative;
      padding: 0 0 0 48px;

      i {
        text-align: center;
        font-size: 28px;
        position: absolute;
        z-index: 2;
        color: #000;
        left: 5px;
        top: 7px
      }

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        background: #32cccc;
        height: 38px;
        width: 38px;
        left: 0;
        border-radius: 50%;
        display: block;
      }
    }
  }
}

.account_manage_helpTab {
  overflow: hidden;
  height: 48px;
  @include font15;
  position: relative;

  &:after {
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    position: absolute;
    width: 100%;
    content: "";
    bottom: 0;
    z-index: 1;
    height: 48px;
  }

  li {
    float: left;
    width: 104px;
    text-align: center;
    padding: 14px 0 14px;
    height: 48px;
    position: relative;
    border-bottom: solid 1px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    &:hover {
      border-bottom: 3px solid $color-primary;
    }
  }

  .active {
    border-bottom: 3px solid $color-primary;
  }
}

.account_manage_helpManual {
  ul {
    margin: -24px 0 0;
  }

  li {
    border: solid 1px #e6eaed;
    border-left: solid 6px #6d7278;
    padding: 16px 32px;
    overflow: hidden;
    border-radius: 5px 0 0 5px;
    margin: 0 0 16px;

    @media screen and (max-width:$break-mid) {
      display: flex;
      flex-direction: column;
      padding: 16px;
    }

    strong {
      float: left;
      margin: 12px 0 7px;
      font-size: 19px;
      @media screen and (max-width:$break-mid) {
        @include font15;
      }
    }

    button {
      float: right;
      // width: 115px;
      font-size: 15px !important;
      // padding: 0 5px !important;
    }
  }
}
