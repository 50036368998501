@use '@common/waffle-styled/color';

.menu {
  &[class~='ant-menu'] {
    border: none !important;
  }

  li[class~='ant-menu-item'] {
    height: 48px;
    padding: 7px 26px;

    font-size: 12px;
    font-weight: 700;
    color: rgb(0 0 0 / 54%);

    background-color: #fff;

    span {
      position: relative;
      display: block;
      line-height: 34px;
    }
  }

  li[class~='ant-menu-item-active'] {
    color: color.$color-mint-400 !important;
  }

  li[class~='ant-menu-item-selected'] {
    color: color.$color-mint-600 !important;
    background-color: #fff !important;
  }
}
