@use '../../styles/common/size' as waffle-size;
@use '../../styles/common/color' as waffle-color;
@use '../../styles/common/font' as waffle-font;

%button {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: waffle-size.$size-2 1px;
  cursor: pointer;
  padding: waffle-size.$size-2 waffle-size.$size-8;
  min-width: 40px;
  vertical-align: middle;

  &[data-cmd='quoteDefault'],
  &[data-cmd='quoteLine'],
  &[data-cmd='unquote'] {
    svg path {
      transform: scale(0.6) translateY(-4px);
      transform-origin: top left;
    }
  }

  &[data-cmd='unquote'] {
    svg path {
      transform: scale(0.8) translateY(-4px);
    }
  }

  &:hover {
    background-color: waffle-color.$color-mint-100;

    svg path {
      fill: waffle-color.$color-mint-600;
    }
  }

  &::after {
    top: waffle-size.$size-16;
    margin-left: waffle-size.$size-2;
  }

  i {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: auto;

    &::before {
      transition: color 500ms;
      margin-bottom: waffle-size.$size-2;
    }

    &:hover {
      &::before {
        color: waffle-color.$color-mint-600;
      }
    }

    &[class*='icon'] {
      font-size: 24px;
    }

    svg {
      margin: auto;
      margin-bottom: waffle-size.$size-2;
    }

    label {
      @include waffle-font.font(waffle-size.$size-10, 400);
      cursor: pointer;
      color: waffle-color.$color-gray-900;
    }
  }
}
