.container {
  position: relative;
  height: 175px;
}
.antCloseButton {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;

  &[class~='ant-btn'] svg {
    color: #fff;
  }
}
.haveContent {
  padding: 36px 14px 0 30px;
  max-width: 240px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  right: auto;
  color: #fff;

  background: rgba(0, 0, 0, 0.6);

  @media screen and (max-width: 768px) {
    max-width: 184px;
  }

  strong {
    font-size: 19px;
    font-weight: 500;
    line-height: 28px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  small {
    line-height: 22px;
    height: 22px;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.bgColor {
  display: flex;
  align-items: center;
  margin-top: 14px;
  height: 28px;
  font-size: 12px;
  color: #fff;
  font-weight: bold;
  width: 58px;
  justify-content: center;
}
// .noneContent {}
.image {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
