@mixin font($size: 14px, $weight: 400) {
  font-size: $size;
  font-weight: $weight;

  @if $size < 30px {
    line-height: 1.5;
  }

  @else {
    line-height: 1.35;
  }
}
