@import '../../containers/MainContainer/MainContainer.module.scss';
.ad_list {
  h2 {
    font-size: 32px;
    line-height: 120%;
    margin: 0 0;
  }

  h2+p {
    margin: 13px 0 18px;
  }

  .ad_list_manage {
    float: right;
    text-align: right;
    padding: 0;
    width: 100%;
    margin: 0;
    &:after {
      @include clear
    }

    button {
      margin-left:20px;
      font-size: 24px;
    }

    span {
      display: none;
    }
  }

  .dropdown_align {
    text-align:right;
    float: left;    width: calc(100% - 102px);
    padding: 5px 0 0;

  }
}

.ad_list_loop {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  justify-content: space-between;
  width: 100%;
}

.ad_listNull {
  max-width: 100%;
}

@keyframes ldio-yzt3xtxgned {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}

div.loadingBar {
  text-align:center;
}

.ldio-yzt3xtxgned div {
  position: absolute;
  width: 62px;
  height: 62px;
  border: 10px solid #00b2b2;
  border-top-color: transparent;
  border-radius: 50%;
}
.ldio-yzt3xtxgned div {
  animation: ldio-yzt3xtxgned 0.8s linear infinite;
  top: 50px;
  left: 50px
}
.loadingio-spinner-rolling-hcw45k9qjb {
  width: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  background: rgba(255, 255, 255, 0);
}
.ldio-yzt3xtxgned {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.6);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-yzt3xtxgned div { box-sizing: content-box; }

.navbar {
  display: none;

  @media screen and (max-width:$break-mid) {
    display: block;
  }

  padding: 0 0 22px;
  width: 100%;
  ul.navbar_list {
    display: block;
    margin: 0;
    padding: 0;

    li.navbar_item {
      display: inline-block;
      color: #1d2129;
      font-size: 13px;
      line-height: 18px;

      &:last-child > i { display: none; }
    }
  }
}

.empty_container {
  display: flex;
  flex-direction: column;
  & > button {
    width: 320px;
  }
}

// 광고 가이드
.guideBtn_wrap {
  &>a {
    display: inline-block;
  }

  .guideBtn {
    border: solid 1px #99b2ff;
    background-color: #eff3fd;
    color: #4167d9;
    width: 320px;
    display: block;
    font-size: 17px !important;

    &:hover {
      border: solid 1px #7396ff;
      background-color: #d0dbfb;
    }

    &>div {
      margin-bottom: 6px;
    }
  }
}
.guide_download_icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  top: 3px;
  background-image: url('../../assets/images/intro/download.svg');
  background-size: contain;

  &.disable {
    background-image: url('../../assets/images/intro/download_not_available.svg');
  }

  &.ad_guide {
    background-image: url('../../assets/images/intro/download.svg');
    width: 20px;
    height: 20px;
    margin-left: 2px;
  }
}