@import '../AdCreateContent.module.scss';

.create_ad_container_step1 {
  hgroup {
    h3:before {
      content: "1";
    }
  }

  em.helper {
    font-size: 13px;
    color: $color-grey;
    display: block;
    width: 100%;
    margin: 10px 0 0;
  }
}

.ad_notification_info {
  font-size: 11px;
}
