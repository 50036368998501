@import '../../index.scss';

header.header_wrap {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.header_inner {
  display: flex;
  margin: 0 auto;
  padding: 0 24px;
  width: 100%;
  height: 64px;

  h1 {
    height: 22px;
    margin: 20px 0;

    img {
      filter: invert(100%);
      height: 26px;
      width: auto;
    }
  }

  h3 {
    margin: 21px 0;
    margin-left: 4px;
  }

  .left,
  .right {
    display: flex;
    align-items: center;
  }

  .left>a {
    display: flex;
    align-items: center;

    .logo_text {
      margin-left: 9px;
      color: rgba(0, 0, 0, .84);
      font-size: 24px;
      line-height: 33px;
      font-weight: 900;
    }
  }

  .right {
    margin-left: auto;

    button {
      margin-left: 16px;
    }

    .create_ad {
      padding: 0 30px;
      float: right;

      @media screen and (max-width:$break-mid) {
        background: none;
        color: #00c4c3;
        border: none;
        width: 96px;
        font-size: 15px !important;
        padding: 0 !important;
      }
    }
  }
}

@media screen and (max-width:$break-mid) {
  .isMo {
    display: block;
  }

  .header_inner {
    height: 56px;
    padding: 0;

    h1 {
      height: 18px;
      margin: 20px 0 20px 64px;
      &.intro_wadiz_logo {
        margin: 20px 0;
      }
    }

    .login_logo {
      margin: 14px 0 16px 0;
    }
  }
}
