/**
* Size
**/
$size-1: 1px;
$size-2: 2px;
$size-4: 4px;
$size-6: 6px;
$size-8: 8px;
$size-10: 10px;
$size-12: 12px;
$size-14: 14px;
$size-16: 16px;
$size-18: 18px;
$size-20: 20px;
$size-22: 22px;
$size-24: 24px;
$size-28: 28px;
$size-32: 32px;
$size-36: 36px;
$size-40: 40px;
$size-48: 48px;
$size-56: 56px;
$size-60: 60px;
$size-64: 64px;
$size-72: 72px;
$size-80: 80px;
$size-120: 120px;
$size-160: 160px;
