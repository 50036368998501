/* stylelint-disable indentation, value-keyword-case */

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;

@font-face {
  font-family: Roboto;
  font-weight: $font-weight-light;
  font-style: normal;
  src: local('Roboto Light'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Light.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Light.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Light.ttf') format('truetype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: Roboto;
  font-weight: $font-weight-regular;
  font-style: normal;
  src: local('Roboto'),
    local('Roboto'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Regular.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Regular.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: Roboto;
  font-weight: $font-weight-medium;
  font-style: normal;
  src: local('Roboto Medium'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Medium.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Medium.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: Roboto;
  font-weight: $font-weight-bold;
  font-style: normal;
  src: local('Roboto Bold'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Bold.woff2') format('woff2'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Bold.woff') format('woff'),
    url('https://static.wadiz.kr/assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  unicode-range: U+000-5FF; /* Latin glyphs */
}

