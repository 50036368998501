@import '../../containers/MainContainer/MainContainer.module.scss';

.adList_detail {
  .date_range_control {
    overflow: hidden;
    float: right;
    margin:40px 0  10px;

    li {
      float: left;
      color: rgb(144, 148, 156);
      cursor: pointer;

      &::before {
        content: "|";
        margin: 0 10px;
        font-weight: normal;
        color: rgb(230, 234, 237);
      }

      &:first-child::before {
        content: "";
      }

      &.active {
        font-weight: bold;
        color: rgb(0, 178, 143);
      }
    }
  }
}

.tablePageLength {
  margin-left: auto;
  width: auto !important;

  @media screen and (max-width:$break-mid) {
    display: none !important;
  }
}

.ad_table_btnArea {
  margin-bottom: 16px;
}

.ad_list_graph {
  margin-top: 100px;  
  overflow: auto;
  width: 100%;
}

.ad_table {
  @media screen and (max-width:$break-mid) {
    min-width: 800px !important;
  }
  thead tr th {
    @media screen and (max-width:$break-mid) {
      &:nth-child(1) {
        width: 40px !important;
      }
      &:nth-child(3) {
        width: 120px !important;
      }
      &:nth-child(4) {
        width: 80px !important;
      }
      &:nth-child(5) {
        width: 50px !important;
      }
      &:nth-child(6) {
        width: 80px !important;
      }
      &:nth-child(7) {
        width: 90px !important;
      }
      &:nth-child(8) {
        width: 70px !important;
      }
      &:nth-child(9) {
        width: 50px !important;
      }
      &:nth-child(10) {
        width: 50px !important;
      }
      &:nth-child(11) {
        width: 50px !important;
      }
    }
  }
}

.ad_table_detail_total {
  @media screen and (max-width:$break-mid) {
    min-width: 657px !important;
  }
  thead tr th {
    @media screen and (max-width:$break-mid) {
      &:nth-child(1) {
        width: 40px !important;
      }
      &:nth-child(3) {
        width: 60px !important;
      }
      &:nth-child(4) {
        width: 80px !important;
      }
      &:nth-child(5) {
        width: 90px !important;
      }
      &:nth-child(6) {
        width: 70px !important;
      }
      &:nth-child(7) {
        width: 65px !important;
      }
      &:nth-child(8) {
        width: 65px !important;
      }
      &:nth-child(9) {
        width: 67px !important;
      }
    }
  }
}

.ad_table_detail_date {
  @media screen and (max-width:$break-mid) {
    min-width: 657px !important;
  }
  thead tr th {
    @media screen and (max-width:$break-mid) {
      &:nth-child(1) {
        width: 70px !important;
      }
      &:nth-child(3) {
        width: 60px !important;
      }
      &:nth-child(4) {
        width: 70px !important;
      }
      &:nth-child(5) {
        width: 65px !important;
      }
      &:nth-child(6) {
        width: 65px !important;
      }
      &:nth-child(7) {
        width: 67px !important;
      }
    }
  }
}