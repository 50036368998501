@import '../../../../components/Header/Header.module.scss';
$assetUrl: '../../../../assets/images/intro/';

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #fff;
  text-align: center;

  @media screen and (max-width: $break-mid) {
    padding: 60px 16px;
  }
}

.title {
    [class*='title'] {
      @media screen and (max-width: $break-mid) {
        font-size: 24px;
        line-height: 34px;
      }
    }
  }

.buttonSection {
  display: flex;
  flex-direction: row;
  justify-items: center;
  text-align: center;
  margin: 0 auto;
  gap: 16px;

  @media screen  and (max-width:$break-mid){
    flex-direction: column;
  }
}

.guidBtn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition-property: background-color, border-color, color, opacity;
  transition-duration: 0.2s;
  border: solid 1px #7396FF;
  border-radius: 8px;
  background-color: #eff3fd;
  cursor: pointer;
  padding: 12px 25px;
  box-sizing: border-box;
  -webkit-appearance: none;
  height: 56px;
  gap: 8px;

  @media screen and (max-width:$break-mid) {
    height: 52px;
  }

  span {
    color: #7396FF;
    font-size: 20px;
    font-weight: 700;

    @media screen and (max-width:$break-mid) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.downloadIcon {
  display: inline-block;
  position: relative;
  background-image: url($assetUrl+'icon_download_blue.svg');
  width: 24px;
  height: 24px;
}
