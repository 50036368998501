$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;
$break-lid: 1280px;

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #F2F4F6;
  text-align: center;

  @media screen and (max-width: $break-mid) {
      padding: 60px 16px;
      gap: 24px;
    }
}

.productWrap {
  display: flex;
  max-width: 1280px;
  gap: 16px;

  @media screen and (max-width:$break-mid) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
  }
}

.product {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
  gap: 16px;
  width: 100%;
  max-width: 416px;
  height: 100%;

  @media screen and (max-width:$break-mid) {
    max-width: 100%;
    padding: 16px;
    gap: 12px;
  }

  &-1 {
    .item-1 {
      .header {
        background-color: transparent;
        padding: 0 0 16px 0;

        @media screen and (max-width: $break-mid) {
          padding-bottom: 12px;
        }

        > h4 {
          width: 100%;
          text-align: center;
          color: #868E96;
        }
      }
      .content {
        padding: 0;
        background-color: transparent;

        .item {
          .header {
            background-color: #868E96;
            padding: 8px 16px;

            > h4 {
              width: auto;
              text-align: left;
              color: #fff;
            }
          }

          .content {
            padding: 16px;
            background-color: #F2F4F6;
          }
        }
      }
    }
  }

  &-2 {
    .item-1 {
      .content {
        background-color: #B9EEEE;

        .item {
          .header {
            background-color: #00B2B2;

            @media screen and (max-width:$break-mid) {
              flex-direction: column;
              align-items: flex-start;
            }

            h4 {
              font-size: 16px;
            }
          }

          .content {
            background-color: #fff;
          }
        }
      }
    }
  }
}

.description {
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
}

.titleWrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;

  .title {
    flex-direction: column;
    text-align: left;

    span {
      color: #212529;
      font-size: 16px;
      line-height: 28px;
      font-weight: 700;
    }

    h3 {
      font-size: 28px;
      line-height: 38px;
    }

    + span {
      font-size: 10px;
      color: rgba(0, 0, 0, .4);
      line-height: 14px;
      font-weight: 700;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;

  .header {
    padding: 8px 16px;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #343A40;

    @media screen and (min-width: $break-mid + 1) and (max-width: $break-lid) {
      flex-direction: column;
      align-items: flex-start;
    }

    h4 {
      font-size: 18px;
      color: #fff;
      line-height: 28px;
    }

    .price {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      color: rgba(255, 255, 255, 0.60);
      font-weight: 500;
      line-height: 20px;

      b { font-weight: 700;}

      .line {
        display: inline-flex;
        width: 1px;
        height: 12px;
        margin: 0 4px;
        background-color: rgba(255, 255, 255, .6);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    background-color: #F2F4F6;
    padding: 16px;
    border-radius: 0 0 8px 8px;

    .title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 48px;
        white-space: nowrap;
        padding: 4px 8px;
        border-radius: 8px;
        background-color: #5CDEDE;
        font-size: 12px;
        color: #fff;
        font-weight: 700;
        line-height: 18px;
      }

      strong {
        font-size: 16px;
        color: #212529;
        font-weight: 700;
        line-height: 28px;
        text-align: left;
        letter-spacing: -.5px;
        white-space: pre-wrap;
      }
    }

    p {
      display: block;
      color: #868E96;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      text-align: left;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 24px;
  width: 561px;
  height: 86px;
  margin-top: -9px;
  text-align: center;
  border: 1px solid #343A40;
  font: {
    size: 32px;
    weight: 700;
  }
  color: #343A40;

  @media screen and (max-width: $break-mid) {
    width: 203px;
    height: 52px;
    font-size: 18px;
    margin-top: 7px;
  }
}

.contentBtn {
  margin-top: 0;
  width: auto;
  height: 36px;

  font: {
    size: 15px;
    weight: 700;
  }

  @media screen and (max-width: $break-mid) {
    width: 211px;
    margin: 0 auto;
  }
}
