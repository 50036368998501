.container {
  position: absolute;
  bottom: 16px;
  display: block;

  a {
    position: relative;
    display: block;
    padding: 15px 26px 0 26px;
    height: 48px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 13px;

    svg {
      position: absolute;
      top: 10px;
    }
    span {
      position: relative;
      font-weight: 700;
      left: 30px;
    }
  }
}
