@import '../CommonDialog/Common.module';

// 광고 구좌 선택
#adAccount {
  header {
    .dialog_title {
      p {
        margin: 16px 0 20px;
        color: $color-slate;
      }
    }
  }

  .dialog_adAccount_date_picker,
  .account_select_date {
    position: relative;
    z-index: 1001;

    .date_range_input {
      display: flex;
      align-items: center;

      & > div {
        margin-right: 10px;
      }

      .range_ico {
        margin-right: 10px;
      }

      @media screen and (max-width: $break-mid) {
        flex-wrap: wrap;
        align-items: flex-start;

        .range_ico {
          width: 30px;
          margin-right: 0 !important;
          padding: 12px 0 0 5px;
        }

        & > div {
          float: left;
          flex: none;
          width: calc(100% - 40px);
          margin: 0 10px 8px 0;

          & > div {
            width: 100%;

            & > div > div {
              width: 100%;
            }
          }
        }

        & > button {
          width: calc(100%);
          margin-top: 12px;
        }
      }
    }
  }
}

.dialog_adAccount {
  section {
    width: 100%;
    padding: 40px 0;
    border-bottom: 1px solid #e2e2e2;

    .helper_content {
      min-height: 24px;

      @media screen and (max-width: $break-mid) {
        position: relative;
        top: 24px;
      }
    }

    & > div {
      padding-top: 25px;

      &:first-child {
        padding-top: 0;
      }
    }

    h4 {
      margin-bottom: 6px;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
    }

    @media screen and (max-width: $break-mid) {
      p {
        @include font15;
      }
    }
  }

  .account_select {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .account_select_legend {
      // display: flex;
      // align-items: top;
      position: absolute;
      top: 40px;
      right: 0;

      span {
        margin-left: 12px;
        font-size: 13px;

        &::before {
          content: '';

          display: inline-block;

          width: 12px;
          height: 12px;
          margin-right: 5px;

          vertical-align: middle;

          border-radius: 1px;
        }

        @media screen and (max-width: $break-mid) {
          margin: 0 12px 0 0;
        }
      }

      .selected::before {
        background-color: $color-primary;
      }

      .selectable::before {
        border: solid 1px $color-grey;
      }

      .notSelectable::before {
        background-color: #f0f2f5;
        border: solid 1px #dadce0;
      }

      @media screen and (max-width: $break-mid) {
        position: relative;
        top: 20px;
      }
    }

    .account_select_button {
      // @media screen and (max-width:$break-mid) {
      //   margin: 0 0 20px 0;
      // }

      button {
        width: 40px;
        height: 40px;
        margin-right: 5px;
        padding: 0;

        &.gray-outline:hover {
          color: #fff;
          background-color: $color-primary;
          border-color: $color-primary;
        }

        &.disabledAccount {
          color: #dadce0;
          background-color: #f0f2f5;
          border: solid 1px #dadce0;
        }

        @media screen and (max-width: $break-mid) {
          padding: 0 16px;
        }
      }
    }
  }

  .recommend_msg {
    padding: 10px 0 8px;
    font-size: 14px;
    color: $color-grey;
    text-align: center;
  }

  .account_select_content {
    padding-top: 20px;
  }

  .account_selected_content {
    padding-top: 20px;
  }

  .account_selected {
    .cancellation {
      overflow-x: auto;
    }

    .ad_table {
      min-width: 100%;

      th,
      td {
        button {
          margin: 0 2px;
          color: rgb(0 0 0 / 84%);

          &.btn_accountNum {
            cursor: default;
            border-color: rgb(0 0 0 / 30%);
          }

          span {
            display: none;
          }
        }

        &:first-child {
          text-align: left;
        }
      }
    }
  }

  .dialog_adAccount_position {
    position: relative;

    &::after {
      @include clear;
    }

    ul {
      flex-flow: row nowrap;

      li {
        flex-shrink: 0;
        align-items: stretch;

        width: 100px;
        margin: 0 0 20px;

        text-align: center;

        & i {
          margin: 0 !important;
          font-size: 36px;
        }

        button {
          position: relative;

          width: 100%;
          min-width: 140px;
          padding: 254px 0 0;

          font-size: 13px;
          font-weight: bold;
          color: $color-grey;
          vertical-align: top !important;

          border: none;

          .btn_position_imgWrap {
            position: relative;
            display: block;
            height: 240px;
            margin: 0 0 16px;

            &::before,
            &::after,
            img {
              @include absoluteCenter;
            }
          }

          &.disabledArea {
            color: #dadce0;

            .btn_position_imgWrap {
              &::before {
                content: '';
                z-index: 3;
                background: rgb(225 225 225 / 70%);
                border-radius: 3px;
              }

              img {
                border-radius: 3px;
              }
            }
          }

          // &.active,
          // &:hover {
          &.active {
            color: rgb(0 0 0 / 84%);

            .btn_position_imgWrap {
              &::before {
                content: '';
                z-index: 3;
                background: rgb(0 178 178 / 70%);
                border-radius: 3px;
              }

              &::after {
                content: '\e934';

                z-index: 34;

                height: 34px;

                font-family: wadizicon;
                font-size: 34px;
                font-weight: bold;
                color: white;
                text-align: center;
              }

              img {
                border-radius: 3px;
              }
            }
          }
        }
      }
    }

    & > button {
      float: right;
      height: 36px;

      @media screen and (max-width: $break-mid) {
        width: 100%;
        height: 48px;
      }
    }
  }
}
