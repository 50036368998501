$break-mid: 768px;

.container {
  padding: 0 40px 0 0;

  @media screen and (max-width: $break-mid) {
    padding: 0;
  }

  li {
    list-style-type: disc;
  }
}
.adGuide {
  padding: 40px 0;

  @media screen and (max-width: $break-mid) {
    padding: 40px 0;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
