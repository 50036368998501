.buttonContainer {
  float: right;

  &:after {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
  }

  button {
    margin-left: 10px
  }
}
