$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;

.container {
  background: linear-gradient(180deg, #D6FFFF 0%, #B2F0F0 100%);
  width: 100%;
  height: 396px;
  text-align: center;
  color: #212529;

  @media screen and (max-width: $break-mid) {
    height: 618px;
  }
}

.notice {
  flex: 0 0 50%;
  text-align: left;

  h2, div {
    line-height: 44px;
    font-size: 32px;
    font-weight: 700;
  }

  h2 {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.54);
    margin-bottom: 8px;
  }

  .pcText {
    display: block;
  }

  .moText {
    display: none;
  }

  @media screen and (max-width: $break-mid) {
    width: 100%;
    text-align: center;
    flex: 0;

    div, h2 {
      display: inline-block;
      vertical-align: middle;
      font-size: 18px;
      line-height: 150%;
    }

    h2 {
      font-size: 16px;
      margin-top: 24px;
      margin-bottom: 8px;
    }

    .pcText {
      display: none;
    }

    .moText {
      display: block;
      margin-bottom: 24px;
    }
  }
}

// key visual 영역 + 문구
.keyVisualWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1280px;
  height: 340px;
  margin: 0 auto;

  @media screen and (max-width: $break-mid) {
    flex-direction: column-reverse;
    justify-content: center;
    height: 562px;
    padding: 40px 16px;
  }
}

.keyVisual {
  background-image: url($assetUrl + 'intro_keyVisual@2x.png');
  background-repeat: no-repeat;
  background-size: 308px 236px;
  background-position: center;
  width: 308px;
  height: 340px;
  margin-right: 80px;

  @media screen and (max-width: $break-mid) {
    background-image: url($assetUrl + 'intro_keyVisual@3x.png');
    background-size: 240px 184px;
    width: 240px;
    height: 184px;
    margin: 0;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  width: 100%;
  height: 56px;
  background-color: #fff;

  @media screen and (max-width: $break-mid) {
    padding: 0 16px;
    height: 56px;
  }
}

.headerContent {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  height: 56px;

  h1 {
    height: 100%;
    display: flex;
    align-items: center;

    img {
      width: auto;
      height: 32px;

      @media screen and (max-width: $break-mid) {
        height: 16px;
      }
    }
  }

  h3 {
    margin: 31px 0 0 8px;
    color: #00b2b2;
    font-size: 9px;
    line-height: 1;
  }
}

.headRight {
  display: flex;
  align-items: center;
  margin-left: auto;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #212529;
    font-size: 20px;
    font-weight: 700;

    i {
      margin-right: 4px;
      height: 22px;
      font-size: 18px;

      @media screen and (max-width: $break-mid) {
        margin: 0;
        height: 24px;
        font-size: 24px;
      }
    }
  }
}

.shortcut {
  color: #495057;
  text-align: center;

  /* Body1・16/Medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */

  @media screen and (max-width: $break-mid) {
    font-size: 14px;
  }
}
