.ad-status {
  margin-right: 10px;
  .bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    overflow: hidden;
  }
}

.adDate-ad-status {
  margin-right: 10px;
  font-size: 8px !important;
  .bullet {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    overflow: hidden;
  }
}
