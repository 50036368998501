
@import '../../containers/MainContainer/MainContainer.module.scss';

.point_red {
  color: #ff8181 !important;
}
//검색결과 레이어
.sidebar_searchResult {
  padding: 30px 30px 0;
  box-shadow: 0 20px 16px -10px rgba(0, 0, 0, 0.15);
  border: solid 1px #1ebfbf;
  background: #fff;
  width: 342px;
  position: absolute;
  z-index: 1;

  @media screen and (max-width:$break-mid) {
    width: 100%;
  }

  &::after {
    clear: both;
    content: "";
    display: block
  }

  button {
    float: right;
    margin: 20px 0;
  }

  section {
    overflow: auto;
    max-height: 300px;
    border-bottom: 1px solid #d8d8d8;

    &.sidebar_searchResult_null {
      padding: 30px;
      text-align: center;

      p {
        margin: 10px 0 0;
        font-size: 13px;
        color: #7c8288;
      }

      i {
        font-size: 52px;
        color: #dadce0;
      }
    }

    dl {
      border-top: 1px solid #d8d8d8;
      padding-top: 30px;

      &:first-child {
        border: none;
        padding-top: 0;
      }

      dt,
      dd {
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
        line-height: 1.38;

        // word-wrap: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
      }

      dt {
        max-height: 40px;
        font-size: 15px;
      }

      dd {
        font-size: 13px;
        color: #7c8288;
        padding-left: 12px;
        max-height: 36px;
        position: relative;

        i {
          font-size: 8px;
          position: absolute;
          left: 0;
          top: 2px
        }
      }
    }
  }
}
