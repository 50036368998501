$break-mid: 768px;

.wrapper {
  display: flex;
  width: 100%;
}

.container {
  display: inline-block;
  margin: 0 auto;
  width: 140px;
  min-width: 140px;
}

.areaImage {
  display: inline-block;
  position: relative;
  margin-bottom: 18px;
  border-radius: 3px;
  background: no-repeat 50%/cover border-box;
  cursor: pointer;
  width: 140px;
  height: 240px;

  &.disabled {
    cursor: auto;
    color: #dadce0;

    &:before {
      position: absolute;
      background: rgba(225, 225, 225, 0.7);
      width: 100%;
      height: 100%;
      content: '';
    }
  }

  &.selected {
    color: rgba(0, 0, 0, 0.84);

    &:before {
      position: absolute;
      background: rgba(0, 178, 178, 0.7);
      width: 100%;
      height: 100%;
      content: '';
    }

    &:after {
      position: absolute;
      top: 103px;
      left: 53px;
      height: 34px;
      text-align: center;
      color: white;
      font-family: "wadizicon";
      font-size: 34px;
      font-weight: bold;
      content: '\e934';
    }
  }
}

.areaPackageTitle {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 140px;
  vertical-align: top !important;
  text-align: center;
  line-height: 0;
  color: #90949c;
  font-size: 12px;
  font-weight: normal;
}

.areaTitle {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 140px;
  vertical-align: top !important;
  text-align: center;
  line-height: 13px;
  color: #90949c;
  font-size: 14px;
  font-weight: bold;
}
