@import './CommonDialog/Common.module.scss';

// 광고 기간설정
#adDate {
  .dialog_title {
    p {
      font-size: 13px;
      line-height: 140%;
      margin: 30px 0 36px;
    }
  }

  .dialog_priod {
    margin: 20px auto 40px;

    h4 {
      margin: 0 0 20px;
      font-size: 19px;

      color: rgba(0, 0, 0, 0.84);

      @media screen and (max-width: $break-mid) {
        display: flex;
        flex-direction: column;
      }

      em {
        float: right;
        @include font15;
        font-weight: bold;
      }
    }

    .dialog_priod_select {
      background-color: rgb(248, 248, 248);
      padding: 20px 0 10px;
      overflow: hidden;
      margin: 0 0 40px;

      & > div {
        display: flex;
        max-width: 376px;
        width: 100%;
        margin: 0 auto 10px;

        strong {
          font-size: 17px;
          font-weight: bold;
          height: 36px;
          min-width: 102px;
          padding: 8px 0 0;
        }

        .rpDropdown {
          line-height: 34px;
          width: calc(100% / 3);
          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }

        .dateInput {
          input[type='text'] {
            text-align: center;
          }
        }

        div {
          width: 274px;
        }

        input[type='text'] {
          background-color: rgb(240, 240, 240);
          width: 100%;
          height: 36px;
          @include font15;
          color: rgb(109, 114, 120);

          &:hover,
          &:focus {
            border: 1px solid rgba(0, 0, 0, 0.15);
          }
        }
      }

      @media screen and (max-width: $break-mid) {
        & > div {
          flex-wrap: wrap;
          justify-content: space-between;
          padding: 0 26px;

          strong {
            width: 100% !important;
          }

          .rpDropdown {
            width: calc((100% / 3) - 5px);
            margin-right: 5px;
          }

          div {
            width: 100%;
          }
        }
      }
    }
  }
}

// 성과보고서 즐겨 찾기
#reportFavorit textarea {
  margin-top: 30px;
  font-weight: bold;
}

.dialog_shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.dialog_wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 100%;
  height: auto;
  box-shadow: 0 0 2px 0 #999;

  // p {
  //   font-size: 17px !important;
  // }

  &.typeAlert {
    max-width: 560px;
    padding: 32px 24px;

    .dialog_title {
      margin: 12px auto;
    }

    //신용카드 정보 삭제
    table {
      th,
      td {
        height: 78px;
      }

      & + p {
        margin-top: 20px;
      }
    }
  }

  &.typePopup {
    max-width: 920px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    width: calc(100% - 40px);
    padding: 40px;

    .dialog_title,
    .dialog_button,
    .dialog_content {
      max-width: 640px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }

    .dialog_title {
      margin: 32px auto 28px;
    }
  }

  header {
    .dialog_close {
      text-align: right;
      height: 24px;

      button {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    h3 {
      font-size: 27px;
      min-height: 36px;
      // margin: 12px 0;
      line-height: 140%;
    }

    p {
      font-size: 17px;
    }
  }

  .helper {
    font-size: 13px;
    color: $color-grey;
    margin: 10px 0 0;
  }

  .bg_color_box {
    width: 136px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 17px;
    color: #fff;
  }

  .dialog_content {
    font-size: 17px;

    h5 {
      @include font15;
      color: rgba(0, 0, 0, 0.54);
      font-weight: bold;
      margin: 0 0 25px;
    }
  }

  .dialog_button {
    text-align: right;
    margin-top: 32px;

    button {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: $break-small) {
    padding: 32px 24px !important;
    max-width: 400px !important;

    header {
      .dialog_title {
        margin: 12px auto !important;

        h3 {
          font-size: 24px !important;
          min-height: 32px !important;
        }

        p {
          @include font15;
        }
      }
    }
  }

  @media screen and (max-width: $break-min) {
    top: 0;
    transform: translate(-50%, 0%);
    width: 100% !important;
    max-width: 100% !important;

    &.typePopup {
      max-height: 100vh;
    }

    header {
      .dialog_close {
        text-align: left;
      }
    }
  }
}

// 신용카드 결제,간편 결제 정보 등록
.creditCard {
  max-width: 640px;
  margin: 32px auto 28px;

  .viewPay_total {
    border-radius: 2px;
    border: solid 1px #dbdbdb;
    background-color: #f8f8f8;
    padding: 30px 24px;
    overflow: hidden;
    margin-bottom: 24px;

    span {
      font-size: 17px;
    }

    strong.point_red {
      float: right;
      font-size: 36px;
    }
  }

  .creditCardInfo {
    border-bottom: 10px solid rgba(226, 226, 226, 0.3);
    margin-bottom: 8px;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 42px;

      &.creditCardNo {
        div {
          display: inline-flex;
          align-items: center;
        }
      }

      &.creditCardExp {
        label:not(:first-child) {
          width: 114px;
        }

        span {
          margin: 0 20px 0 10px;
        }

        label {
          padding: 0;

          select {
            padding: 0 36px 0 24px;
          }
        }
      }

      &.creditCardPw {
        align-items: flex-start;

        input {
          width: 114px;
          margin: 0 10px 10px 0;
        }

        em {
          display: block;
          color: #9b9fa6;
          width: 100%;
          flex-shrink: 0;
        }
      }

      & > label:first-child {
        width: 160px;
        margin-right: 38px;
        flex-shrink: 0;
      }

      div {
        width: 100%;

        span {
          margin: 0 5px;
        }
      }
    }
  }

  .creditCard_checkbox_wrap {
    padding: 12px 18px;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $break-mid) {
      flex-direction: column;

      & > span {
        margin-top: 5px;
        padding-left: 32px;
      }
    }

    & > label {
      span {
        font-size: 15px !important;
        color: #868e96;
        line-height: 24px !important;
      }
    }

    & > span {
      font-size: 13px;
      color: var(--slate-grey);
      text-decoration: underline;
    }
  }

  .helper {
    display: none;
    &.active {
      display: block;
    }

    padding: 16px 20px;
    margin: 0;
    background-color: #f5f7fa;
    font-size: 12px;
  }

  .creditCard_select {
    label {
      margin: 0 0 5px;
    }

    li {
      color: #82878c;
      @include font15;
      padding: 0 0 10px 10px;
      position: relative;

      &:last-child {
        padding-bottom: 5px;
      }

      &:before {
        content: '•';
        font-size: 24px;
        position: absolute;
        left: -7px;
        top: -8px;
        // margin: 0 3px 0 0;
        color: #ffb500;
      }
    }

    p.point_red {
      margin-bottom: 30px;
      font-size: 13px;
    }
  }

  @media screen and (max-width: $break-mid) {
    .creditCardInfo {
      li {
        flex-wrap: wrap;

        & > label:first-child {
          width: 100% !important;
          margin: 0 0 8px;
        }
      }
    }
  }
}

.creditCardPopup {
  .dialog_button {
    button {
      &.loading {
        background-size: 25%;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjI1IDI1IDUwIDUwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxzdHlsZT4uY2lyY3VsYXIgeyBhbmltYXRpb246IHJvdGF0ZSAxLjVzIGxpbmVhciBpbmZpbml0ZTsgdHJhbnNmb3JtLW9yaWdpbjogMTAwJSAxMDAlOyB9IC5wYXRoIHsgc3Ryb2tlLWRhc2hhcnJheTogMSwgMjAwOyBzdHJva2UtZGFzaG9mZnNldDogMDsgYW5pbWF0aW9uOiBkYXNoIDEuNXMgZWFzZS1pbi1vdXQgaW5maW5pdGU7IHN0cm9rZS1saW5lY2FwOiByb3VuZDsgc3Ryb2tlOiAjZmZmOyB9IEBrZXlmcmFtZXMgcm90YXRlIHsgMTAwJSB7IHRyYW5zZm9ybTogcm90YXRlKDM2MGRlZyk7IH0gfSBAa2V5ZnJhbWVzIGRhc2ggeyAwJSB7IHN0cm9rZS1kYXNoYXJyYXk6IDEsIDIwMDsgc3Ryb2tlLWRhc2hvZmZzZXQ6IDA7IH0gNTAlIHsgc3Ryb2tlLWRhc2hhcnJheTogODksIDIwMDsgc3Ryb2tlLWRhc2hvZmZzZXQ6IC0zNXB4OyB9IDEwMCUgeyBzdHJva2UtZGFzaGFycmF5OiA4OSwgMjAwOyBzdHJva2UtZGFzaG9mZnNldDogLTEyNHB4OyB9IH08L3N0eWxlPjxnIGNsYXNzPSJjaXJjdWxhciI+PGNpcmNsZSBjbGFzcz0icGF0aCIgY3g9IjUwIiBjeT0iNTAiIHI9IjIwIiBmaWxsPSJub25lIiBzdHJva2Utd2lkdGg9IjQiIHN0cm9rZS1taXRlcmxpbWl0PSIxMCIvPjwvZz48L3N2Zz4=);
      }
    }
  }
}
