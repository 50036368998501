@import '../AdModifyContent.module.scss';

em.helper {
  font-size: 13px;
  color: $color-grey;
  display: block;
  width: 100%;
  margin: 10px 0 0;
}

.cautionMsg {
  position: relative;
  max-width: 640px;
  width: 100%;
  margin-bottom: 40px;
  margin: 40px 0;
  padding: 20px 16px 20px 20px;
  border-radius: 3px;
  background-color: #fff1f1;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #f25555;
}

.modify_ad {
  section {
    margin-bottom: 16px;
  }
}

