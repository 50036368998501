@import '../../containers/MainContainer/MainContainer.module.scss';

.ad_sidebar {
  min-width: 240px;
  width: 240px;
  position: relative;
  box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06);

  h3 {
    color: #fff;
    line-height: 100%;
    padding: 32px 26px;
    font-size: 19px;
    font-weight: bold;
    background-color: $color-primary;
  }

  & > ul {
    li {
      font-size: 13px;
      padding: 15px 26px 0;
      height: 48px;
      font-weight: bold;

      a {
        color: rgba(0, 0, 0, 0.54);

        &.active {
          color: #00a2a2;
          font-weight: bold;
        }
      }
    }
  }

  // 계정정보
  .sidebar_info {
    margin-top: 188px;

    i {
      margin-right: 10px;
      font-size: 20px;
      vertical-align: middle;
    }
  }

  @media screen and (max-width: $break-mid) {
    &,
    h3,
    .sidebar_info {
      display: none;
    }
  }
}

/* 광고리스트 */
.adlist {
  // 검색
  .sidebar_search {
    margin: 24px 26px 0;
    position: relative;

    input[type='search'] {
      padding: 0 36px 0 12px;
      height: 36px !important;
      @include font15;
      font-weight: normal;
    }

    & > button {
      position: absolute;
      right: 12px;
      top: 0;
      height: 36px !important;

      span {
        display: none;
      }
    }

    //검색결과 레이어
    .sidebar_searchResult {
      padding: 30px 30px 0;
      box-shadow: 0 20px 16px -10px rgba(0, 0, 0, 0.15);
      border: solid 1px #1ebfbf;
      background: #fff;

      &::after {
        @include clear;
      }

      button {
        float: right;
        margin: 20px 0;
      }

      section {
        overflow: auto;
        max-height: 300px;
        border-bottom: 1px solid #d8d8d8;

        &.sidebar_searchResult_null {
          padding: 30px;
          text-align: center;

          p {
            margin: 10px 0 0;
            font-size: 13px;
            color: #7c8288;
          }

          i {
            font-size: 52px;
            color: #dadce0;
          }
        }

        dl {
          border-top: 1px solid #d8d8d8;
          padding-top: 30px;

          &:first-child {
            border: none;
            padding-top: 0;
          }

          dt,
          dd {
            overflow: hidden;
            white-space: normal;
            text-overflow: ellipsis;
            line-height: 1.38;

            // word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 10px;
          }

          dt {
            max-height: 40px;
            @include font15;
          }

          dd {
            font-size: 13px;
            color: #7c8288;
            padding-left: 12px;
            max-height: 36px;
            position: relative;

            i {
              font-size: 8px;
              position: absolute;
              left: 0;
              top: 2px;
            }
          }
        }
      }
    }
  }

  // 리스트
  .sidebar_list {
    font-size: 13px;

    ul.hide li {
      display: none;
    }

    li {
      border-bottom: 1px solid #dddee1;
      position: relative;

      strong,
      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        cursor: pointer;
      }

      &:last-child {
        border: none;
      }
    }

    .sidebar_list_pjName {
      & > li {
        padding: 16px 0 0;

        .sidebar_list_pjButton {
          display: flex;

          & > button {
            background: #fff;
            right: 20px;
            top: 10px;
            position: absolute;
            padding: 0 !important;

            &:hover {
              padding: 0 !important;
              width: auto !important;
            }

            span {
              display: none;
            }

            i {
              font-size: 20px;
              color: $color-slate;
            }
          }
        }

        strong {
          cursor: pointer;
          padding: 0 40px 0 26px;
          line-height: 140%;
          max-height: 42px;
        }

        .sidebar_list_adName {
          margin-top: 16px;

          li {
            color: #1d2129;
            position: relative;
            background-color: #f0f2f5;
            padding: 20px 26px;
            line-height: 150%;

            span {
              max-height: 40px;
              margin-left: 10px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.active {
              background-color: #a0a0a0;
              color: #fff;

              span {
                color: #fff;
              }
            }

            i {
              position: absolute;
              left: 26px;
              font-size: 10px;
            }

            .sidebar_list_btnWrap {
              margin: 10px 0 0 10px;
              text-align: center;

              button {
                width: calc(50% - 4px);
                max-width: 110px;
                margin-right: 4px;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }

  .block_lnb {
    position: relative;

    &:after {
      width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.6);
    }
  }

  @media screen and (max-width: $break-mid) {
    position: fixed;
    display: block;
    background: #fff;
    z-index: 97;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    top: 57px;
    width: 100%;

    &.is_mobile_none {
      display: none;
    }
    .sidebar_list_pjButton {
      padding: 10px 0;
      button {
        top: 20px !important;
      }
    }
    .sidebar_search {
      margin: 24px 16px 0;

      .sidebar_searchResult {
        width: 100%;
      }
    }

    .sidebar_list {
      overflow: auto;
      height: calc(100vh - 117px);
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .sidebar_list_adName {
        li {
          padding: 20px 26px !important;

          .sidebar_list_btnWrap {
            margin-left: 0 !important;
          }
        }
      }
    }
  }
}

/* 광고만들기 */
.sidebar_step {
  .ad_sidebar_stepNo {
    counter-reset: chapter 0;
    padding: 14px 0 0 26px;

    & > li {
      position: relative;
      z-index: 2;
      font-weight: bold;
      font-size: 13px;
      opacity: 0.5;

      &:before {
        width: 20px;
        text-align: center;
        height: 20px;
        position: relative;
        z-index: 2;
        font-size: 13px;
        margin-right: 10px;
        line-height: 100%;
        padding-top: 4px;
        border-radius: 50%;
        color: #fff;
        display: inline-block;
        counter-increment: chapter;
        content: counter(chapter) '';
        background-color: #b6b6b6;
      }

      &.passed,
      &.active {
        &:before {
          background-color: #ff8181;
        }
      }

      &.passed {
        ul:after {
          background-color: #ff8181;
        }
      }

      &.active {
        opacity: 1;
      }

      .ad_sidebar_stepInfo {
        padding: 15px 0 30px;

        &:after {
          display: block;
          content: '';
          width: 2px;
          top: 0;
          z-index: 1;
          left: 9px;

          height: 100%;
          position: absolute;
          background-color: #b6b6b6;
        }

        li {
          height: 30px;
          font-weight: normal;
          padding-left: 30px;
        }
      }
    }
  }

  @media screen and (max-width: $break-mid) {
    width: 100%;
    background-color: #fafbfc;
    padding: 0;
    margin: 0;
    display: block;
    height: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .ad_sidebar_stepNo {
      padding: 10px 0 0;

      & > li {
        width: calc(100% / 3);
        display: inline-block;
        text-align: center;

        &:before {
          display: block;
          margin: 0 auto 2px;
        }

        .ad_sidebar_stepInfo {
          padding: 0;

          &:after {
            width: calc(100% - 10px);
            top: 10px;
            left: 50%;
            height: 2px;
          }
        }
      }
    }

    h3,
    .ad_sidebar_stepInfo li {
      display: none;
    }
  }
}

.ad_listNull {
  padding: 60px 0;
}
