$break-mid: 768px;

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: margin 0.5s;
  z-index: 1001;
  margin: -60px 1px 0;
  background: #212529;
  min-height: 60px;
  overflow-y: hidden;
  text-align: center;

  @media screen and (max-width:$break-mid) {
    margin: -60px 0 0;
    padding: 0 20px;
    text-align: left;
  }

  &.show {
    margin-top: 0;
  }

  p {

    color: #fff;
    font-size: 16px;

    @media screen and (max-width:$break-mid) {
      font-size: 18px;
      font-weight: 700;
    }
  }

  em {
    color: #00b2b2;
  }

  br {
    display: none;

    @media screen and (max-width:$break-mid) {
      display: inline-block;
    }
  }

  a {
    display: inline-block;
    margin-left: 40px;
    text-decoration: underline;
    color: #fff;
    font-size: 14px;

    @media screen and (max-width:$break-mid) {
      margin: 4px 0 0;
    }
  }
}
