.timeline_box{
  // height: 800px;
  overflow: auto;
  .timeline_controller{
    display: flex;
    justify-content: center;
    margin-bottom: 22px;
    .control_btn {
      font-size: 20px;
      color: #dadce0;
    }
    .current_date{
      margin: 0 40px;
      font-size: 21px;
      font-weight: 500;
      text-align: center;
      color: rgba(0, 0, 0, 0.84);
    }
  }
  .legend_box{
    display: flex;
    margin-bottom: 13px;
    .legend_item{
      display: flex;
      align-items: center;
      margin-right: 16px;
      font-size: 13px;
      color: rgba(0, 0, 0, 0.84);
      .legend_ico{
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 8px;
        margin-bottom: 2px;
        border-radius: 50%;
        overflow: hidden;
        background: #000;
        &.type_1 { background: #00b2b2; }
        &.type_2 { background: #ffb9b9; }
        &.type_3 { background: #f2ae00; }
        &.type_4 { background: #4167d9; }
        &.type_5 { background: #60656a; }
        &.type_6 { background: rgba 0 0 0, 0.3; }
        &.type_7 { background: #f0f2f5; }
      }
    }
  }
}

