@import '../../containers/MainContainer/MainContainer.module.scss';

.create_ad {
  position: relative;
  padding-top: 40px;

  @media screen and (max-width:$break-mid) {
    padding: 0;
  }

  hgroup {
    h2 {
      margin-bottom: 32px;
    }

    h3 {
      font-size: 19px;
      margin-bottom: 10px;
      &::before {
        width: 20px;
        text-align: center;
        height: 20px;
        position: relative;
        z-index: 2;
        font-size: 13px;
        line-height: 120%;
        margin: 0 8px 0 0;
        padding: 2px 0 0;
        border-radius: 50%;
        color: #fff;
        float: left;
        border: 1px solid #fff;
        background-color: #ff8181; 
      }
    }

    p {
      margin-bottom: 40px;
    }
  }
}
