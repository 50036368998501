@import '../../index.scss';

.header_gnb {
  height: 48px;
  border-top: 1px solid rgba(0, 0, 0, .1);
  background-color: #fafbfc;

  button.isMo {
    cursor: pointer;

    i {
      font-size: 24px;
      height: 24px;
      width: 24px;
    }

    span {
      display: none;
    }

    &.toggle_menu {
      height: 56px;
      padding: 0 16px;

      i:before {
        margin: 0;
      }
    }

    &.btn_close {
      z-index: 100;
      position: relative;
    }
  }

  .header_gnbList_main {
    margin: 0 auto;
    max-width: 320px;
    width: 100%;
    // height: 48px;

    &>li {
      display: inline-block;
      width: calc(100% / 3);
      text-align: center;
      min-height: 47px;
      border-bottom: 3px solid #fafbfc;

      &.active {
        border-color: $color-primary;
        color: rgba(0, 0, 0, .84);
      }

      a {
        padding: 12px 10px;
        display: block;
        height: 44px;
        color: rgba(0, 0, 0, .39);
      }
    }

    .header_gnbList_sub {
      display: none;
    }
  }

  @media screen and (max-width:$break-mid) {
    position: absolute;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    left: 0;
    z-index: 99;
    bottom: 0;
    width: 0;
    height: 100%;
    border: none;


    .header_gnbList {
      display: none;
      position: fixed;
      bottom: 0;
      right: 0;
      top: 0;
      left: 0;
      background: #fff;
      padding: 18px 16px;
      z-index: 98;
      width: 240px;
      box-shadow: 1px 0 0 0 rgba(0, 0, 0, 0.06);

      .header_gnbList_main {
        display: block;
        margin-top: 23px;
        overflow: auto;
        height: 100%;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
          display: none;
        }

        &>li {
          width: 100%;
          display: block;
          text-align: left;
          border-bottom: none;
          font-size: 17px;
          font-weight: bold;
          padding: 10px 8px 18px;

          a {
            padding: 0 2px;
            color: #000;
            height: auto;
          }
        }

        .header_gnbList_sub {
          display: block;
          border-top: 1px solid #000;
          margin-top: 4px;

          li {
            @include font15;
            font-weight: 300;
            padding: 14px 0;
            height: 48px;

            &.active {
              font-weight: bold;

              a {
                color: $color-primary;
              }
            }
          }
        }
      }
    }

    .header_authMenu {
      position: absolute;
      bottom: 0;
      margin-left: 256px;
      display: none;
      left: 0;

      a {
        display: block;
      }

      button {
        width: 90px;
        margin-bottom: 16px;
      }
    }
  }
}
