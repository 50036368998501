.icon {
  display: block;
  transform: scale(1);
  transition: transform 300ms ease-out;
  width: 1em;
  height: 1em;
  fill: currentColor;

  &.inline {
    display: inline;
  }

  &.flip {
    transform: scale(-1, 1);
  }

  &.flipVertical {
    transform: scale(1, -1);
  }
}
