$break-mid: 768px;

.container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;

  @media screen and (max-width:$break-mid) {
    display: block;
  }
}
.contentWrapper {
  position: relative;
  width: calc(100% - 240px);
  max-width: 1200px;
  padding: 33px 0px 50px 66px;

  @media screen and (max-width:$break-mid) {
    padding: 30px 16px;
    width: 100%;
  }

  &:after {
    display: block;
    content: "";
    clear: both;
  }

  hgroup {
    p {
      color: #777c81;
      font-size: 13px;
      min-height: 24px;
      margin-top: 10px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;

      a {
        display: contents;
      }
    }
  }

  h2 {
    font-size: 36px;
    line-height: 125%;
    min-height: 44px;
    display: flex;
    width: 100%;
    align-items: center;

    @media screen and (max-width:$break-mid) {
      font-size: 24px;
      min-height: 36px;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.point_link_blue {
  color: #395bbf;
  text-decoration: underline;
}
