.container {
  position: relative;
}
.previewTypeKwd {
  border: solid 1px rgba(0, 0, 0, 0.06);
  height: 175px;
}

.previewCopy {
  display: flex;
  align-items: center;
  padding: 8px 16px;
  background-color: #f0f2f5;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 279px;
  height: 40px;
  border-radius: 20px;

  i {
    font-size: 24px;
  }

  strong {
    margin-left: 8px;
  }
}
