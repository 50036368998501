@mixin HeadFont {
  /* KR/Body2/Regular */
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  /* Greyscale/G900 */
  color: #212529;

  box-sizing: content-box;
  padding: 1px;
}

$keyColumnWidth: 260px;
.keywordHeader {
  @include HeadFont;
  max-width: $keyColumnWidth;
}
.keywordContent {
  @include HeadFont;
  max-width: $keyColumnWidth;
  text-align: center;
}
.keywordContentUnavailable {
  @include HeadFont;
  max-width: $keyColumnWidth;
  text-align: center;
  color: #ff8181;
  text-decoration: line-through;
}

.countHeader {
  @include HeadFont;
}
.countContent {
  @include HeadFont;
  text-align: center;
}
.countContentUnavailable {
  @include HeadFont;
  text-align: center;
  color: #ff8181;
  text-decoration: line-through;
}

.selectHeader {
  @include HeadFont;
}
.row {
  td {
    padding: 4px !important;
  }
}
.empty {
  height: 23px;
}
.pagination {
  margin: 32px auto 0 !important;
  width: fit-content;
}
