@import '../../containers/MainContainer/MainContainer.module.scss';

.settings {
  .content_wrap {
    width: 100%;

    h2 {
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 6px;

      small {
        @include font15;
        color: $color-slate;
      }
    }

    section {
      margin-top: 40px;

      h3 {
        font-weight: normal;
        font-size: 17px;
      }
    }
  }
}

.settings_taxBill {
  h3+label {
    color: #777c81;
  }

  input[type="text"] {
    max-width: 560px !important;
    float: left;

    @media screen and (max-width:$break-mid) {
      max-width: calc(100% - 98px ) !important;
    }
  }

  .taxbill_checkbox {
    display: inline-block !important;
  }
  button {
    width: 78px !important;
    font-size: 15px !important;
    margin-left: 12px !important;
  }
}

.settings_refund {
  .refund_input_wrap {
    display: flex;

    .rpDropdown {
      line-height: 34px !important;
    }

    label,
    &>div {
      max-width: 300px;
      width: 100%;
      margin-right: 10px;
    }

    @media screen and (max-width:$break-mid) {
      flex-direction: column;

      label,
      &>div {
        margin: 10px 0 0;
        max-width: 100%;
      }
    }
  }
}

.setting_simpleAccount {
  .manageCard {
    &::after {
      @include clear
    }

    li {
      width: 100%;
      max-width: 343px;
      height: 200px;
      float: left;
      border-radius: 5px;
      position: relative;
      margin: 0 30px 30px 0;
      color: #fff;
    }

    .manageCard_regist {
      background-image: linear-gradient(to right, #00b28f, #00b278);

      button {
        @include absoluteCenter;
        width: 36px;
        height: 36px;

        i {

          font-size: 36px;
        }
      }
    }

    .manageCard_registed {
      background-image: linear-gradient(to right, rgba(0, 178, 175, 0.64), rgba(0, 178, 178, 0.86));
      font-size: 21px;
      padding: 20px;

      small {
        font-size: 13px;
        display: block;
        margin: 0 0 10px;
      }

      i {
        position: absolute;
        font-size: 24px;
        right: 20px;
        top: 20px;
        margin: auto;
        width: 24px;
        height: 24px;
      }
    }

    button {
      color: #fff !important;

      span {
        display: none;
      }
    }
  }
}
