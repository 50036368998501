$break-mid: 768px;

.container {
  .title {
    font-size: 32px;
    font-weight: 700;
    line-height: 44px;

    @media screen and (max-width: $break-mid) {
      font-size: 24px;
      line-height: 34px;
      white-space: nowrap;
    }
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    margin-top: 16px;

    > div {
      display: flex;
      align-items: center;
      gap: 4px;
    }
  }

  .pcBr {
    display: block;

    @media screen and (max-width: $break-mid) {
      display: none;
    }
  }

  .moBr {
    display: none;

    @media screen and (max-width: $break-mid) {
      display: block;
    }
  }
}
