.loadingBar-wrapper {
    width: 100%;
    height:500px;
    text-align: center;
    padding-top:30px;
}

@keyframes ldio-yzt3xtxgned {
    0% { transform: translate(-50%,-50%) rotate(0deg); }
    100% { transform: translate(-50%,-50%) rotate(360deg); }
  }
  .ldio-yzt3xtxgned div {
    position: absolute;
    width: 62px;
    height: 62px;
    border: 10px solid #00b2b2;
    border-top-color: transparent;
    border-radius: 50%;
  }
  .ldio-yzt3xtxgned div {
    animation: ldio-yzt3xtxgned 0.8s linear infinite;
    top: 50px;
    left: 50px
  }
  .loadingio-spinner-rolling-hcw45k9qjb {
    width: 60px;
    height: 60px;
    display: inline-block;
    overflow: hidden;
    background: rgba(255, 255, 255, 0);
  }
  .ldio-yzt3xtxgned {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.6);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-yzt3xtxgned div { box-sizing: content-box; }
  /* generated by https://loading.io/ */