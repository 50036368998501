@import '../../containers/MainContainer/MainContainer.module.scss';
.tooltip_box {
    font-size: 18px;
    display: inline-block;
    cursor: pointer;

    span {
      display: none;
    }

    i {
      font-size: 18px;
    }


  }
