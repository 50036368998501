.container {
  padding: 28px 24px;
}
.title {
  /* KR/Title3/Bold */
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  /* identical to box height, or 136% */

  letter-spacing: -0.006em;

  /* Greyscale/G900 */
  color: #212529;

  margin-bottom: 32px;
}
