.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 60px 0;
  background-color: #fafbfc;
  color: rgba(107, 112, 118, 0.5);
  span p {
    display: block;
  }
}
