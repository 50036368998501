.container {
  position: relative;
  padding: 24px 12px;
}
.antCloseButton {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 2;
}
