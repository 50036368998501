@use '../styles/common/size' as waffle-size;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/font' as waffle-font;

$input-transition-duration: 150ms;

.input {
  display: block;
  transition-property: border-color, background-color, color;
  transition-duration: $input-transition-duration;
  outline: none;
  border: 1px solid waffle-color.$color-gray-300;
  border-radius: 4px;
  background-color: waffle-color.$color-rgba-light-100;
  padding: 0 16px;
  width: 100%;
  height: 48px;
  vertical-align: middle;
  color: waffle-color.$color-gray-900;
  -webkit-appearance: none;

  @include waffle-font.font(waffle-size.$size-16, 400);

  @media (hover: hover) {
    &:hover {
      border-color: waffle-color.$color-gray-600;
    }
  }

  &:focus,
  &.active {
    border-color: waffle-color.$color-mint-600;
  }

  &[readonly],
  &[disabled] {
    border-color: waffle-color.$color-gray-100;
    background-color: waffle-color.$color-gray-000;
    cursor: default;
    pointer-events: none;
  }

  &[disabled],
  &.disabled {
    color: waffle-color.$color-gray-500;
  }

  &.error {
    border-color: waffle-color.$color-red-600;
  }

  &::-webkit-input-placeholder {
    color: waffle-color.$color-gray-500;
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  &.sm {
    height: 32px;
    min-height: 32px;

    @include waffle-font.font(waffle-size.$size-12, 400);
    font-size: 14px;
  }

  &.md {
    height: 40px;
    min-height: 40px;

    @include waffle-font.font(waffle-size.$size-14, 400);
  }

  &.lg {
    height: 48px;
    min-height: 48px;

    @include waffle-font.font(waffle-size.$size-16, 400);
  }

  &.xl {
    height: 56px;
    min-height: 56px;

    @include waffle-font.font(waffle-size.$size-18, 400);
  }
}
