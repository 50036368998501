$break-mid: 768px;

.main_container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;

  @media screen and (max-width:$break-mid) {
    display: block;
  }

  .content_wrap {
    position: relative;
    width: calc(100% - 240px);
    max-width: 1200px;
    padding: 33px 60px 50px 66px;

    @media screen and (max-width:$break-mid) {
      padding: 30px 16px;
      width: 100%;
    }

    &:after {
      display: block;
      content: "";
      clear: both;
    }
  }
}
