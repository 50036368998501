.main_container {
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;

  // @media screen and (max-width: $break-mid) {
  //   display: block;
  // }
}

.content_wrap {
  position: relative;
  width: 100%;
  max-width: 1200px;
  padding: 33px 60px 50px 66px;

  // @media screen and (max-width: $break-mid) {
  //   padding: 30px 16px;
  //   width: 100%;
  // }

  &:after {
    display: block;
    content: '';
    clear: both;
  }

  hgroup {
    p {
      color: #777c81;
      font-size: 17px;
      min-height: 24px;
      margin-top: 10px;
      display: inline-flex;
      align-items: center;
      width: 100%;
      flex-grow: 1;

      a {
        display: contents;
      }
    }
  }

  h2 {
    font-size: 36px;
    line-height: 125%;
    min-height: 44px;
    display: flex;
    width: 100%;
    margin-bottom: 10px;
    align-items: center;

    // @media screen and (max-width: $break-mid) {
    //   font-size: 24px;
    //   min-height: 36px;
    //   display: inline-block;
    //   overflow: hidden;
    //   text-overflow: ellipsis;
    // }
  }

  p {
    margin-bottom: 6px;

    // small {
    //   @include font15;
    //   color: $color-slate;
    // }
  }

  section {
    margin-top: 40px;

    h3 {
      font-weight: normal;
      font-size: 17px;
    }
  }
}

.agreeContainer {
  display: flex;
  padding: 10px 8px 2px 0;
}

.nonAgreeContainer {
  display: flex;
  flex-direction: column;
  padding: 4px 8px 2px 0;
}

.notice {
  color: #60656a;
  font-size: 13px;
}

.buttonContainer {
  margin-top: 12px;
  height: 48px;
  button {
    position: relative;
    padding-top: 14px !important;
  }
}

.agreeBox {
  width: 64px;
  height: 24px;
  padding: 0 8px;
  margin-right: 8px;
  text-align: center;
  line-height: 24px;
  border-radius: 4px;
  background-color: #e7f9f9;
  color: #00b2b2;
  font-size: 12px;
  font-weight: bold;
}

.agreeDate {
  margin-right: 8px;
}

.terms {
  margin-left: 8px;
  font-size: 12px;
  text-decoration: underline;
  color: #00b2b2;
  cursor: pointer;
}
