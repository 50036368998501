@import '../AdCreateContent.module.scss';

.create_ad_container_step3 {
  hgroup {
    h3:before {
      content: "3";
    }
  }
}

.thad_listNull {
  text-align: center;
  width: 100%;
  max-width: 640px;
  color: rgba(107, 112, 118, .5);
  font-size: 13px;
  background: #fafbfc;
  overflow: hidden;
  padding: 160px 0 !important;

  i {
    font-size: 40px;
    display: block;
    padding-bottom: 8px;
    color: $color-slate;
    opacity: 0.5;
  }

  @media screen and (max-width:$break-mid) {
    max-width: 100%
  }
}