@import '../../../containers/MainContainer/MainContainer.module.scss';
.guide {
  position: absolute;
  text-align: left;
  top: 30px;
  max-width: 300px;
  width: 100%;
  z-index: 30;
  margin: 0 !important;
  background-color: #44484b !important;
  color: #fff !important;
  left: auto;
  border-radius: 2px;

  @media screen and (max-width:$break-small) {
    left: 0;
    right: auto;
  }

  dt {
    font-weight: bold;
    font-size: 12px;
  }

  dd {
    line-height: 140% !important;
    font-size: 12px;
  }

  a {
    color: #fff;
  }

  button {
    &>a {
      color: rgba(0, 0, 0, 0.54);
    }

    text-align: right;
    padding: 0;
    font-size: 12px !important;
    height: auto !important;
    width: 100%;
    text-decoration: underline !important;

    @media screen and (max-width:$break-mid) {
      color: #fff;
    }
  }
}