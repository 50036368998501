.container {
  padding-bottom: 16px;
}

.textCopy {
  :global {
    textarea {
      resize: none;
    }
    .ant-input-data-count {
      left: 0;
      bottom: -23px;
      font-size: 12px;
    }
  }
}
