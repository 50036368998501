@import '../../index.scss';

.content_wrap {
  &:after {
    @include clear;
  }
}

.error {
  border-color: #f66 !important;
  background-color: rgba(255, 155, 155, 0.06) !important;
}

/* define : typo */
h1,
h2,
h3,
h4,
h5,
h6,
strong,
dt {
  font-weight: bold;
}

.point_primary {
  color: $color-primary !important;
}

.point_red {
  color: #ff8181 !important;
}

.point_black {
  color: #797979;
}

a.point_link_blue {
  color: #395bbf !important;
  text-decoration: underline;
}

.table_time {
  display: block;
  color: rgba(0, 0, 0, 0.3);
}

/* define : button */
button {
  // padding-top: 0 !important;

  i {
    margin-right: 5px;
  }
}

.button_320 {
  max-width: 320px;
  width: 100%;
  line-height: 100%;

  @media screen and (max-width: $break-mid) {
    max-width: 100%;
  }
}

/* define : common */
.buttonArea {
  margin: 114px 0 0;
  overflow: hidden;

  @media screen and (max-width: $break-mid) {
    margin: 30px 0 0;
  }

  .button_historyBack {
    color: #000;
    font-size: 17px;
    margin-right: 36px;
    vertical-align: middle;

    @media screen and (max-width: $break-mid) {
      margin-right: 24px;

      & + .button_320 {
        // width: 256px;
        width: calc(100% - 92px);
      }
    }
  }

  i {
    font-size: 20px;
    margin: 0 10px 0 0;
  }
}

/* 보고서 테이블 */
.cancellation {
  padding: 0 0 0 !important;
  width: 100%;

  .rpDropdown {
    float: right;
    margin: 40px 0 20px;
    line-height: 34px !important;
  }

  .tableWrap {
    clear: both;
    width: 100%;
  }

  th,
  td {
    vertical-align: middle;

    label {
      margin: 10px 0 0;
    }

    label span {
      display: none;
    }
  }
}

.result_select,
.result_search {
  width: 100%;
  display: flex;
  margin-top: 40px;
  margin-bottom: 20px;

  label.rpDropdown {
    margin-right: 10px;
    width: 300px;
    line-height: 34px !important;
  }

  input[type='search'] {
    max-width: 552px;
    width: calc(100% - 88px);
    height: 36px;
    margin-right: 10px;
  }

  button {
    height: 36px !important;
    width: 78px;
    font-size: 15px !important;
    padding: 0 !important;
  }

  @media screen and (max-width: $break-mid) {
    flex-wrap: wrap;
    margin-top: 10px;

    label.rpDropdown {
      line-height: 46px !important;
    }

    label.rpDropdown:first-child {
      margin: 0 0 10px;
      width: 100%;

      & + label {
        width: calc(100% - 88px);
      }
    }

    button,
    input[type='search'] {
      height: 48px !important;
    }
  }
}

.ad_table {
  min-width: 1074px;
  width: 100%;

  &:not(.ad_tableResult):not(.ad_table_total) tr:nth-child(odd) {
    background-color: #fafbfc;
  }

  th {
    background-color: rgb(255, 255, 255) !important;
    height: 48px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.06) !important;
    border-bottom: solid 1px rgb(230, 234, 237) !important;
    // line-height: 140% !important;
    vertical-align: middle !important;
    font-size: 13px;
    font-weight: bold;
    padding: 0.5rem 0;

    caption {
      display: none;
    }

    button {
      font-weight: bold !important;
      color: rgba(0, 0, 0, 0.84) !important;

      &:hover {
        padding: 0 10px !important;
      }

      span {
        display: none;
      }
    }
  }

  tr:hover {
    background-color: none !important;
  }

  .ad_table_group:nth-child(odd) {
    background-color: #fafbfc;
  }

  td {
    background: none;
    vertical-align: middle;
    border-bottom: solid 1px rgb(230, 234, 237) !important;
    text-align: center;
    font-size: 13px;
    font-weight: normal;
    color: #44484b;
    padding: 0.75rem 0.5rem;

    &.point_primary,
    & a {
      // font-weight: bold;
      cursor: pointer;
    }

    .overview_table_time {
      display: block;
      color: rgba(0, 0, 0, 0.3);
    }

    .overview_table_time2 {
      display: inline-block;
      color: rgba(0, 0, 0, 0.3);
    }
  }

  .error_table {
    height: 240px;

    & > div {
      & > i {
        font-size: 40px;
        display: block;
        padding: 0 0 8px;
      }

      display: flex;
      flex-direction: column;
      align-items: center;
      color: rgba(107, 112, 118, 0.5);
      font-size: 13px;
    }
  }

  & .alignLeft {
    text-align: left !important;
  }
}

.ad_table_btnArea {
  float: right;

  button {
    margin-left: 10px;
    height: 36px !important;
    font-size: 15px !important;
  }

  @media screen and (max-width: $break-mid) {
    width: 100%;

    button {
      width: 100%;
      margin: 0 0 !important;
      height: 48px !important;
      font-size: 17px !important;
    }
  }
}

.dot_showCount,
.dot_clickCount,
.dot_clickRate {
  &::before {
    content: '•';
    font-size: 20px;
    margin: 0 3px 0 0;
    vertical-align: bottom;
  }
}

.dot_showCount::before {
  color: rgb(30, 191, 191);
}

.dot_clickCount::before {
  color: rgb(188, 144, 237);
}

.dot_clickRate::before {
  color: rgb(255, 129, 129);
}

.theadLeft,
.typeAlert table {
  border-bottom: 1px solid #e6eaed !important;
  width: 100%;
  table-layout: auto !important;
  font-size: 15px !important;

  td,
  th {
    border-top: 1px solid #e6eaed !important;
    border-bottom: none !important;
    vertical-align: middle;

    small {
      display: block !important;
    }

    strong {
      font-weight: 500;
    }

    em.point_red {
      font-weight: bold;
    }
  }

  th {
    background-color: #fafbfc;
    text-align: left;
    padding: 14px 12px !important;
    width: 152px;
    font-weight: bold;
  }

  td {
    padding: 14px 20px !important;
  }

  .taxbill_sup_detail {
    font-size: 13px;
    padding: 14px 0 14px 10px !important;
    & > div {
      display: flex;
      padding-bottom: 10px;
      &:last-child {
        padding-bottom: 0;
      }

      .sub_title {
        width: 114px;
        font-weight: 500;
        @media screen and (max-width: $break-mid) {
          width: 64%;
        }
      }

      .pay_date {
        @media screen and (max-width: $break-mid) {
          flex-direction: column;
        }
      }

      & > span {
        display: flex;
        .table_time {
          padding-left: 5px;
          color: $color-grey;
          @media screen and (max-width: $break-mid) {
            padding: 0;
          }
        }
      }
    }
  }
}

.theadLeft tr:hover {
  background: none !important;
}

.ad_listNull {
  text-align: center;
  width: 100%;
  max-width: 640px;
  color: rgba(107, 112, 118, 0.5);
  font-size: 13px;
  padding: 60px 0 24px;
  background: #fafbfc;
  overflow: hidden;

  & > i {
    font-size: 40px;
    display: block;
    padding-bottom: 8px;
    color: $color-slate;
    opacity: 0.5;
  }

  button {
    min-width: 207px;
    font-size: 20px;
    text-align: center;
    margin-top: 20px;

    i {
      color: #fff;
      font-size: 20px;
      margin: 0 10px 0 0 !important;
    }
  }

  @media screen and (max-width: $break-mid) {
    max-width: 100%;
  }
}

.ad_element {
  position: relative;
  max-width: 640px;
  // width: calc(100% - 434px);
  margin-bottom: 40px;

  @media screen and (max-width: $break-mid) {
    max-width: 100%;
    width: 100%;
  }
}

/* 도움말 */
.guide_wrap {
  position: relative;
  padding: 0 0 8px;

  &:after {
    @include clear;
  }

  h3,
  h4,
  & > label {
    float: left;
    padding-right: 5px;
    font-size: 17px;
    font-weight: normal;
  }

  &.active {
    .guide {
      display: block !important;
    }
  }
}

.field {
  input,
  textarea {
    // height: 48px !important;
    font-size: 17px;
    font-weight: 300;
    resize: none;
  }

  select {
    font-size: 17px !important;
    font-weight: 300;
    padding: 0 16px 0 0 !important;
  }

  .msgError {
    font-size: 13px;
    margin: 0 0 10px;
    width: 100%;
  }
}

// 예상금액
.ad_payment {
  max-width: 640px;
  margin: 40px 0 20px;

  @media screen and (min-width: $break-max) {
    position: absolute;
    right: 0;
    top: 0;
    width: 300px;
    margin: 0;
  }

  .payment_count {
    margin-bottom: 20px;
    padding: 26px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0);
    background-color: #f0f2f5;

    h3 {
      font-size: 21px;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.54);
      text-align: left;
      padding: 0 0 10px;
      margin: 0 0 26px;
      border-bottom: 1px solid #dadce0;
    }

    p {
      font-size: 17px;
      width: 100%;
      color: rgba(0, 0, 0, 0.84);
      padding: 0 0 10px;

      em {
        float: right;
        font-weight: bold;
      }
    }

    strong {
      display: block;
      color: #f66;
      font-size: 36px;
      margin: 10px 0 0;
      text-align: right;

      small {
        font-size: 21px;
        font-weight: normal;
      }
    }
  }

  button {
    width: 100%;

    i {
      margin: 0 10px 0 0;
    }
  }

  & > p {
    font-size: 13px;
    line-height: 140%;
    padding: 10px 0 0 20px;
    position: relative;

    i {
      position: absolute;
      left: 0;
    }

    span.point_red {
      display: block;
      margin: 20px 0 0;
    }
  }
}

.ad_payment_list {
  position: relative;

  dl {
    min-height: 366px;
    border: 1px solid #e2e2e2;
    padding: 30px;
    margin: 0 0 40px;
    font-weight: bold;
    overflow: hidden;
    max-width: 640px;
    // width: calc(100% - 434px);
    margin-bottom: 40px;

    @media screen and (max-width: $break-mid) {
      max-width: 100%;
      width: 100%;
    }

    dt {
      margin: 0 0 0px;
      font-size: 17px;
      overflow: hidden;

      button {
        float: right;
        padding: 0 10px !important;
      }
    }

    .payment_list_date {
      color: #90949c;
      width: 100%;
      display: block;
      padding: 10px 0;
      text-align: right;
      font-size: 17px;
      font-weight: bold;

      @media screen and (max-width: $break-mid) {
        font-size: 13px;
      }

      .table_time {
        display: inline !important;
        margin: 0 0 0 10px;
      }
    }

    .payment_list_price {
      float: right;
      position: relative;
      bottom: 34px;

      strong {
        font-size: 24px;
        color: #f66;
        padding: 0 0 0 30px;

        @media screen and (max-width: $break-mid) {
          font-size: 21px;
        }
      }
    }
  }
}

.ad_paid {
  p {
    font-size: 17px;
    color: #8e9298;

    strong {
      font-size: 36px;
      display: block;
      color: rgba(0, 0, 0, 0.84);
      margin: 0 0 30px;
      line-height: 120%;
    }
  }

  small {
    color: #797979;
    font-size: 13px;
    display: block;
    color: #c0c3c9;
    margin: 40px 0 0;
    line-height: 140%;
  }

  p.point_red {
    margin: 20px 0 100px;
    font-size: 13px;
  }
}

.is_mobile_none {
  display: block;

  @media screen and (max-width: $break-mid) {
    display: none;
  }
}

// ReportEmpty css
.report_empty {
  p {
    line-height: 145%;
    font-size: 17px;
    color: #6b7076;
    margin-top: 24px;
  }

  button {
    margin-top: 60px;
  }

  @media screen and (max-width: $break-mid) {
    display: flex;
    height: calc(100vh - 116px);
    flex-direction: column;
    flex-wrap: wrap;

    p {
      font-weight: 300;
      margin-top: 10px;
    }

    button {
      margin-top: auto;
    }
  }
}
