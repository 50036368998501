.container {
  min-height: 92px;
  border-radius: 3px;
  background-color: #eff3fd;
  font-family: NotoSansKR;
  font-size: 14px;
  line-height: 1.43;
  padding: 20px;
  margin-bottom: 42px;

  .notation {
    margin-bottom: 14px;
    color: #4167d9;
  }
  .info {
    color: #495057;
    white-space: pre-wrap;
    word-break: break-all;
  }
}
