$color-primary: #00c4c4;

.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .colorButton {
    margin: 0 10px 10px 0;
    color: #fff;
    text-transform: uppercase;
    position: relative;
    width: calc((100% / 4) - 10px);
    padding: 0 10px;
    display: block;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 3px;
    height: 48px;
    cursor: pointer;

    &:hover:before,
    &:focus:before,
    &.active:before {
      content: '';
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }

    &:hover:after,
    &:focus:after,
    &.active:after {
      content: '\e934';
      color: $color-primary;
      text-align: center;
      font-family: 'wadizicon';
      position: absolute;
      width: 100%;
      top: 0;
      padding: 11px 0 0;
      font-size: 24px;
      font-weight: bold;
      left: 0;
      bottom: 0;
    }
  }
}
