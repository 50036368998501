@import '../../../containers/MainContainer/MainContainer.module.scss';

.dialog_shadow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.dialog_wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: #fff;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 100%;
  height: auto;
  box-shadow: 0 0 2px 0 #999;

  // p {
  //   font-size: 17px !important;
  // }

  &.typeAlert {
    max-width: 560px;
    padding: 32px 24px;

    .dialog_title {
      margin: 12px auto;
    }

    table {
      th,
      td {
        height: 78px;
      }

      & + p {
        margin-top: 20px;
      }
    }
  }

  &.typePopup {
    max-width: 920px;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    width: calc(100% - 40px);
    padding: 40px;

    .dialog_title,
    .dialog_button,
    .dialog_content {
      max-width: 640px;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }

    .dialog_title {
      margin: 32px auto 28px;
    }
  }

  header {
    .dialog_close {
      text-align: right;
      height: 24px;

      button {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    h3 {
      font-size: 28px;
      min-height: 36px;
      // margin: 12px 0;
      line-height: 140%;
    }

    p {
      font-size: 17px;
    }
  }

  .helper {
    font-size: 13px;
    color: $color-grey;
    margin: 10px 0 0;
  }

  .bg_color_box {
    width: 136px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 17px;
    color: #fff;
  }

  .dialog_content {
    font-size: 17px;

    h5 {
      @include font15;
      color: rgba(0, 0, 0, 0.54);
      font-weight: bold;
      margin: 0 0 25px;
    }
  }

  .dialog_button {
    text-align: right;
    margin-top: 32px;

    button {
      margin-left: 8px;
    }
  }

  @media screen and (max-width: $break-small) {
    padding: 32px 24px !important;
    max-width: 400px !important;

    header {
      .dialog_title {
        margin: 12px auto !important;

        h3 {
          font-size: 24px !important;
          min-height: 32px !important;
        }

        p {
          @include font15;
        }
      }
    }
  }

  @media screen and (max-width: $break-min) {
    top: 0;
    transform: translate(-50%, 0%);
    width: 100% !important;
    max-width: 100% !important;

    &.typePopup {
      max-height: 100vh;
    }

    header {
      .dialog_close {
        text-align: left;
      }
    }
  }
}
