.wadizOnly {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  color: #90949c;

  li {
    padding-left: 1em;
    text-indent: -1em;
  }

  li:before {
    content: '※';
    padding-right: 5px;
  }
}
