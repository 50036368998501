@import '../../containers/MainContainer/MainContainer.module.scss';

.result_search {
  margin: 0;
  width: 80%;
  @media screen and (max-width:$break-mid) {
    width: 100%;
  }
}

.tablePageLength {
  margin-left: auto;
  width: auto !important;
}

@media screen and (max-width:$break-mid) {
  .ad_table_btnArea {
    button {
      margin-bottom: 10px !important;
    }
  }

}