$break-mid: 768px;

.KeyVisualButton {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  margin-top: 31px;


  @media screen and (max-width: $break-mid) {
    margin-top: 0;
    flex-direction: column;
    gap: 8px;
  }

  i {
    margin-right: 12px;
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 0 24px;
  width: auto;
  height: 48px;
  text-align: justify;
  border-radius: 4px;
  background:  #F2F4F6;

  color:#495057;
  text-align: center;

  /* Body2・14/Medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */

  color: #212529;

  @media screen and (max-width: $break-mid) {
    font-size: 14px;
    width: 100%;
    height: 40px;
  }
}

