.container {
  position: relative;
  margin: 0;
  padding: 0;
}

.title {
  font-size: 17px;
}

.titleContainer {
  display: flex;
  justify-content: space-between;
}
.matters {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: dashed 2px #dadce0;
  margin-bottom: 40px;
}
