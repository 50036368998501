.mobile {
  margin-bottom: 10px;
  background-color: #00c4c4;
  color: #fff;
  border: 1px solid #00c4c4;
  border-radius: 4px;

  padding: 0 10px;
  height: 28px;
  line-height: 26px;
  font-size: 12px;

  width: fit-content;
  height: 26px;
}
