@import '../../containers/MainContainer/MainContainer.module.scss';

.not_found {
  .content_wrap {
    margin: 55px auto 0;

    i {
      font-size: 72px;
      width: 72px;
      height: 72px;
      margin: 0 auto 24px;
      color: #f2ae00;
      display: block;
    }

    h2 {
      font-size: 48px;
      font-weight: 300;
      height: 54px;
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.73);
      justify-content: center;
    }

    p {
      font-size: 16px;
      padding: 10px 0;
      line-height: 1.5;
      text-align: center;
      color: #1d2129;
      margin: 10px 0 16px;
    }

    button {
      margin: 0 auto;
      display: block;
      width: 214px;
    }

    @media screen and (max-width:$break-mid) {
      margin: 46px auto 0;

      h2 {
        font-size: 36px;
      }
    }
  }
}
.footer {
    text-align: center;  
    font-size: 13px;
    color: $color-grey;
    height: 24px;
}