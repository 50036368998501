.react-calendar-timeline {
  border-bottom: 1px solid #1d2129;
}

.react-calendar-timeline * {
  box-sizing: border-box;
}

.react-calendar-timeline .rct-outer {
  display: block;
  overflow: hidden;
  white-space: nowrap; }

.react-calendar-timeline .rct-scroll {
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  overflow-x: scroll;
  overflow-y: hidden;
  -ms-touch-action: none;
  touch-action: none;
}

.react-calendar-timeline .rct-item.hide {
  visibility: hidden;
}

.react-calendar-timeline .rct-item:hover {
  z-index: 88;
}

.react-calendar-timeline .rct-item.ad-date {
  cursor: default !important;
  background: rgb(255, 255, 255) !important;
  border: 1px solid rgb(218, 220, 224) !important;
}

.react-calendar-timeline .rct-item .rct-item-content {
  position: sticky;
  position: -webkit-sticky;
  left: 0px;
  overflow: hidden;
  display: inline-block;
  border-radius: 2px;
  padding: 0 6px;
  height: 100%;
}

.react-calendar-timeline .rct-sidebar {
  overflow: hidden;
  white-space: normal;
  display: inline-block;
  vertical-align: top;
  position: relative;
  box-sizing: border-box;
  border-right: 1px solid #bbb; }
.react-calendar-timeline .rct-sidebar.rct-sidebar-right {
  border-right: 0;
  border-left: 1px solid #bbb;
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row .inner-row {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  margin: 0;
  border-bottom: 1px solid #f1f1f1;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #1d2129;
  &.last-row {
    border-bottom: 1px solid #c0c3ca;
  }
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
  // background: rgba(0, 0, 0, 0.05);
}
.react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even {
  // background: transparent;
}

.react-calendar-timeline .rct-vertical-lines .rct-vl {
  position: absolute;
  border-left: 1px solid #f1f1f1;
  z-index: 30;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.today{
  background: #e7f9f9;
  opacity: 0.5;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first {
  // border-left-width: 2px;
  border-left: 1px solid #90949c;
}
.react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0 {
  // 주말 background color
  background: rgba(250, 246, 225, 0.4);
}

.react-calendar-timeline .rct-horizontal-lines {
  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  user-select: none;
}
.react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
.react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: 1px solid #f1f1f1;
  box-sizing: border-box;
  z-index: 40;
  &.last-row {
    border-color: #c0c3ca;
  }
}

main .react-calendar-timeline .rct-horizontal-lines .rct-hl-even,
main .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd {
  border-bottom: none;
}

.react-calendar-timeline .rct-cursor-line {
  position: absolute;
  width: 2px;
  background: #2196f3;
  z-index: 51; }

.react-calendar-timeline .rct-dateHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  background-color: #fff;
  border-left: 1px solid #f1f1f1;
  &.today{
    background: #f6f9fd;
  }
  &.saturday{
    color: #7da2cf;
  }
  &.sunday{
    color: #ff8181;
  }
  &.border-item{
    border-left: 1px solid #90949c;
  }
}

.react-calendar-timeline .rct-dateHeader-primary {
  background-color: initial;
  border-left: 1px solid #90949c;
  border-bottom: 1px solid #dadce0;
  color: #1d2129;
  font-weight: bold;
  &>span {
    color: #1d2129 !important;
    font-weight: bold !important;
    font-size: 14px !important;
  }
}

.react-calendar-timeline .rct-header-root {
  background: #fafbfc;
  border-bottom: 1px solid #dadce0;
  border-top: 1px solid #6d7278;
}

.react-calendar-timeline .rct-calendar-header {
  // border: 1px solid #bbb;
}

.left-title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #1d2129;
  border-right: 1px solid #6d7278;
}

.adDate-left-title{
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0px;
  font-weight: 500;
  color: #1d2129;
}
