@import '../../containers/MainContainer/MainContainer.module.scss';

.adList_detail {
  hgroup {
    margin-bottom: 40px;
  }
}

.tablePageLength {
  margin-left: auto;
  width: auto !important;
}

.ad_table {
  @media screen and (max-width:$break-mid) {
    min-width: 860px !important;
  }
  thead tr th {
    @media screen and (max-width:$break-mid) {
      &:nth-child(1) {
        width: 40px !important;
      }
      &:nth-child(3) {
        width: 120px !important;
      }
      &:nth-child(4) {
        width: 80px !important;
      }
      &:nth-child(5) {
        width: 50px !important;
      }
      &:nth-child(6) {
        width: 80px !important;
      }
      &:nth-child(7) {
        width: 90px !important;
      }
      &:nth-child(8) {
        width: 70px !important;
      }

      &:nth-child(9) {
        width: 66px !important;
      }
      &:nth-child(10) {
        width: 55px !important;
        .point_primary {
          cursor: auto !important;
        }
      }
      &:nth-child(11) {
        width: 60px !important;
      }
    }
  }

  td {
    padding: 0.75rem 0.1rem;

    &.not_pointer {
      cursor: auto !important;
    }
  }
}

.ad_table_cost_detail {
  min-width: 1174px;
  @media screen and (max-width:$break-mid) {
    min-width: 1005px !important;
  }
  thead tr th {
    @media screen and (max-width:$break-mid) {
      &:nth-child(1) {
        width: 40px !important;
      }
      &:nth-child(3) {
        width: 60px !important;
      }
      &:nth-child(4) {
        width: 80px !important;
      }
      &:nth-child(5) {
        width: 90px !important;
      }
      &:nth-child(6) {
        width: 70px !important;
      }
      &:nth-child(7) {
        width: 65px !important;
      }
      &:nth-child(8) {
        width: 65px !important;
      }
      &:nth-child(9) {
        width: 65px !important;
      }
      &:nth-child(10) {
        width: 65px !important;
      }
      &:nth-child(11) {
        width: 70px !important;
      }
      &:nth-child(12) {
        width: 65px !important;
      }
      &:nth-child(13) {
        width: 55px !important;
      }
      &:nth-child(14) {
        width: 60px !important;
      }
      &:nth-child(15) {
        width: 70px !important;
      }
      &:nth-child(16) {
        width: 65px !important;
      }
    }
  }
}