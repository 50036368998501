.container {
  border-top: 1px solid #e6eaed;
}
.innerBox {
  display: flex;
  position: relative;
  margin: 0 auto;
  padding-right: 80px;
  padding-left: 80px;
  max-width: 1440px;

  @media (max-width: 1095px) {
    display: block;
    padding-top: 24px;
    padding-right: 16px;
    padding-bottom: 32px;
    padding-left: 16px;
  }
}

.infoSection {
  box-sizing: content-box;
  margin-bottom: 0;
  padding-top: 32px;
  padding-bottom: 32px;
  padding-right: 80px;

  @media (max-width: 1095px) {
    margin-bottom: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
  }

  &:first-child {
    width: calc(50% - 90px);
    max-width: 498px;
    @media (max-width: 1095px) {
      width: 100%;
      max-width: 100%;
    }
  }

  &:last-child {
    width: calc(50% - 90px);
    max-width: 400px;
    @media (max-width: 1095px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 16px;
    }
  }
}

.infoTitle {
  display: block;
  color: rgba(0, 0, 0, 0.84);
  font-weight: 700;
  font-size: 12px;
}
.infoList {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}
.copyright {
  margin-top: 4px;
  @media (max-width: 1095px) {
    margin-top: 16px;
  }
  &{
    color: rgba(0, 0, 0, 0.84);
    font-size: 12px;
    font-weight: 700;
  }
}
.bold {
  color: rgba(0, 0, 0, 0.84);
  font-size: 19px;
  font-weight: 700;
}
