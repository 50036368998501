.container {
  position: relative;
  width: 100%;
  padding: 0;

  div[class~='ant-upload'] {
    width: 100%;
  }
}

.helper {
  font-size: 13px;
  color: #90949c;
  display: block;
  width: 100%;
  margin: 10px 0 0;
}

.errMessage {
  font-size: 13px;
  margin: 0 0 10px;
  width: 100%;
  color: #ff8181;
}
