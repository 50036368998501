@use '../styles/common/size' as waffle-size;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/mixins/breakpoints.utils' as waffle-breakpoint;

$easing-function: cubic-bezier(0.08, 0.95, 0.16, 1);

@keyframes show {
  from {
    transform: translateX(100%);
    opacity: 0.2;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes hide {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}

@keyframes showm {
  from {
    transform: translateY(-100%);
    opacity: 0.2;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes hidem {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}

.container {
  position: absolute;
  top: 0;
  right: 16px;
  left: 16px;
  animation-fill-mode: forwards;
  margin: waffle-size.$size-12 auto 0;
  border-radius: 8px;
  background-color: waffle-color.$color-gray-900;
  padding: waffle-size.$size-16;
  box-sizing: border-box;

  &.warning {
    border: 1px solid waffle-color.$color-red-300;
    background-color: waffle-color.$color-red-200;
  }

  &.showMobile {
    animation-name: showm;
    animation-duration: 0.35s;
    animation-timing-function: $easing-function;
  }

  &.hideMobile {
    animation-name: hidem;
    animation-duration: 0.55s;
    animation-timing-function: $easing-function;
  }

  &.show {
    animation-name: show;
    animation-duration: 0.35s;
    animation-timing-function: $easing-function;
  }

  &.hide {
    animation-name: hide;
    animation-duration: 0.55s;
    animation-timing-function: $easing-function;
  }
}

.content {
  @include waffle-font.font(waffle-size.$size-14, 400);
  color: waffle-color.$color-rgba-light-100;
  pointer-events: all;

  &.warning {
    color: waffle-color.$color-red-700;
  }
}

@include waffle-breakpoint.breakpoint-desktop {
  .container {
    top: auto;
    right: 24px;
    bottom: 24px;
    left: auto;
    margin: 0;
    width: 343px;
  }
}
