.stateBadge {
  border-radius: 2px;
  color: #fff;
  height: 25px;
  display: inline-block;
  font-size: 15px;
  line-height: 18px;
  padding: 3px 5px;
  text-align: center;
  min-width: 52px;
}
