@import '../../../../containers/MainContainer/MainContainer.module.scss';

.account_card {
  position: relative;
  max-width: 640px;
  width: 100%;
  margin-bottom: 40px;

  h4 {
    position: relative;
    font-size: 19px;
    padding-bottom: 26px;
    color: $color-primary;
    border-bottom: 4px solid $color-primary;

    button {
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  .account_cardForm {
    border: 0 solid #dadce0;
    border-width: 0 1px 1px;
    padding: 40px 26px 0;

    .ad_element {
      position: relative;
    }

    .field {
      line-height: 100%;
      position: relative;

      textarea {
        resize: none;
      }

      em {
        font-size: 13px;
        color: $color-grey;
        display: block;
        width: 100%;
        margin: 10px 0 0;
      }

      .menual_btn {
        font-size: 15px;
        &.active {
          position: absolute;
          left: 162px;
          bottom: 0;
        }

        @media screen and (max-width: $break-mid) {
          position: initial;
          margin-top: 10px;
          width: 100%;
          &.active {
            position: initial;
          }
        }
      }
    }

    .account_card_term {
      .form_wrap {
        display: flex;
        align-items: center;

        span {
          padding: 0 10px;
        }
      }
      .date_range_input {
        display: flex;
        align-items: center;

        @media screen and (max-width: $break-mid) {
          .range_ico {
            width: 30px;
            padding: 12px 0 0 5px;
            margin-right: 0 !important;
          }

          flex-wrap: wrap;
          align-items: flex-start;

          & > div {
            width: calc(100% - 40px);
            margin: 0 10px 8px 0;
            float: left;
            flex: none;
            & > div {
              width: 100%;
              & > div > div {
                width: 100%;
              }
            }
          }

          & > button {
            width: calc(100%);
            margin-top: 12px;
          }
        }

        & > div {
          margin-right: 10px;
        }

        .range_ico {
          margin-right: 10px;
        }
      }
    }

    .productImg {
      input[type='file'] {
        display: none;
      }
    }

    .account_card_bg {
      .field {
        display: flex;
        width: calc(100% + 10px);
        flex-wrap: wrap;

        .create_button_color {
          margin: 0 10px 10px 0;
          color: #fff !important;
          text-transform: uppercase;
          position: relative;
          width: calc((100% / 4) - 10px);
          padding: 0 10px;
          display: block;

          &:hover:before,
          &:focus:before,
          &.active:before {
            content: '';
            background: rgba(0, 0, 0, 0.7);
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }

          &:hover:after,
          &:focus:after,
          &.active:after {
            content: '\e934';
            color: $color-primary;
            text-align: center;
            font-family: 'wadizicon';
            position: absolute;
            width: 100%;
            top: 0;
            padding: 11px 0 0;
            font-size: 24px;
            font-weight: bold;
            left: 0;
            bottom: 0;
          }
        }
      }
    }
  }

  @media screen and (max-width: $break-mid) {
    max-width: 100%;
    padding-top: 36px;
    .account_card_bg {
      button {
        width: calc((100% / 2) - 10px) !important;
        padding: 0;
      }
    }
  }

  @media screen and (max-width: $break-min) {
    .account_card_term {
      .form_wrap {
        flex-wrap: wrap;

        & > div {
          margin-bottom: 10px;
          max-width: 200px;
        }

        span {
          margin-bottom: 10px;
        }
      }
    }

    .account_card_bg {
      button {
        @include font13;
      }
    }
  }

  section {
    margin-bottom: 16px;
  }

}

.cautionMsg {
  position: relative;
  max-width: 640px;
  width: 100%;
  margin-bottom: 40px;
  margin: 40px 0;
  padding: 20px 16px 20px 20px;
  border-radius: 3px;
  background-color: #fff1f1;
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #f25555;
}
.recommendedContents {
  width: 100%;
}

