@use '../styles/common/size' as waffle-size;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/base' as waffle-base;

@use 'styles/extend';

.wysiwyg-editor {
  word-break: keep-all;
  overflow-wrap: break-word;
  -moz-osx-font-smoothing: grayscale;

  &.is-error {
    .fr-toolbar {
      border-top-color: waffle-color.$color-red-700;
      border-right-color: waffle-color.$color-red-700;
      border-left-color: waffle-color.$color-red-700;
    }

    .fr-wrapper {
      border-right-color: waffle-color.$color-red-700;
      border-left-color: waffle-color.$color-red-700;
    }

    .fr-second-toolbar {
      border-right-color: waffle-color.$color-red-700;
      border-bottom-color: waffle-color.$color-red-700;
      border-left-color: waffle-color.$color-red-700;
    }
  }

  .fr-wrapper {
    border-right-color: waffle-color.$color-gray-300;
    border-left-color: waffle-color.$color-gray-300;

    ::selection {
      background-color: rgba(waffle-color.$color-mint-200, 0.5);
      color: inherit;
    }

    .fr-placeholder {
      font-family: inherit;
    }
  }

  .fr-box {
    .fr-counter,
    .fr-element,
    .fr-toolbar {
      font-family: waffle-base.$font-family;
    }

    .fr-element {
      @include waffle-font.font(waffle-size.$size-16, 400);
      color: waffle-color.$color-gray-900;
    }

    .fr-counter {
      @include waffle-font.font(waffle-size.$size-14, 400);
    }
  }

  .fr-btn-grp {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-color: waffle-color.$color-gray-300;

    .fr-command.fr-btn:not(.fr-selection) {
      @extend %button;

      &.fr-active {
        i {
          color: waffle-color.$color-mint-600;
        }

        svg path {
          fill: waffle-color.$color-mint-600;
        }
      }
    }
  }

  .fr-newline {
    margin-right: 0;
    margin-left: 0;
  }

  .fr-second-toolbar {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-right-color: waffle-color.$color-gray-300;
    border-bottom-color: waffle-color.$color-gray-300;
    border-left-color: waffle-color.$color-gray-300;
  }

  .fr-tooltip {
    @include waffle-font.font(waffle-size.$size-10, 400);
    border-radius: 4px;
    background-color: waffle-color.$color-gray-800;
    padding: waffle-size.$size-4 waffle-size.$size-8;
    font-family: waffle-base.$font-family;
  }

  .fr-qi-helper {
    a.fr-btn.fr-floating-btn {
      @extend %button;
      justify-content: center;
      width: 56px;
      height: 56px;

      i {
        width: 24px;
        line-height: 0;
      }
    }
  }
}

.fr-toolbar {
  &.fr-top {
    top: inherit !important;
    left: inherit !important;
  }

  .fr-btn-grp {
    margin: 0 waffle-size.$size-2;
  }

  .fr-command.fr-btn {
    &:hover {
      i {
        color: waffle-color.$color-mint-600;
      }

      svg path {
        fill: waffle-color.$color-mint-600;
      }
    }
  }
}

.fr-popup {
  border-radius: 8px;
  border-color: waffle-color.$color-gray-300;
  box-shadow: 0 0 5px rgba(10, 22, 70, 0.06), 0 6px 6px -1px rgba(10, 22, 70, 0.1);
  font-family: waffle-base.$font-family;

  .fr-buttons {
    display: flex;

    &:not(.fr-tabs) .fr-command.fr-btn.fr-active:not(.fr-dropdown) {
      i {
        color: waffle-color.$color-mint-600;
      }

      svg path {
        fill: waffle-color.$color-mint-600;
      }
    }

    .fr-command.fr-btn {
      @extend %button;
    }
  }

  .fr-color-set {
    &.fr-table-colors {
      display: block;
      padding: waffle-size.$size-16;
    }

    &.fr-selected-set,
    &.fr-table-colors {
      display: block;
      padding: waffle-size.$size-12;

      > span {
        width: 24px;
        height: 24px;
      }

      .fr-command {
        transition: border-color 300ms;
        margin: waffle-size.$size-8;
        border: 1px solid waffle-color.$color-gray-500;
        border-radius: 4px;

        &::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          transition: border-color 300ms ease-out;
          border: 2px solid rgba(0, 0, 0, 0);
          border-radius: 8px;
          width: 32px;
          height: 32px;
          content: '';
        }

        &.fr-active-item {
          &[data-param1='#FFFFFF'] {
            .fr-selected-color {
              color: waffle-color.$color-gray-900;
            }
          }

          .fr-selected-color {
            line-height: 24px;
          }
        }

        &[data-param1='REMOVE'],
        &[data-param2='REMOVE'] {
          border-color: waffle-color.$color-gray-400;
          background-image: linear-gradient(
            to top right,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 0) calc(50% - 0.8px),
            waffle-color.$color-red-600 50%,
            rgba(0, 0, 0, 0) calc(50% + 0.8px),
            rgba(0, 0, 0, 0) 100%
          ) !important;

          i {
            display: none;
          }
        }

        &[data-param1='none'] {
          border-color: transparent;
          pointer-events: none;
        }

        &:hover {
          outline: none;
          cursor: pointer;

          &::after {
            border-color: waffle-color.$color-gray-300;
          }
        }
      }
    }
  }

  .fr-image-upload-layer {
    @include waffle-font.font(waffle-size.$size-12, 400);
    padding: waffle-size.$size-32 waffle-size.$size-16;
    letter-spacing: normal;
    color: waffle-color.$color-gray-600;

    strong {
      @include waffle-font.font(waffle-size.$size-14, 400);
      display: block;
      margin-bottom: -#{waffle-size.$size-12};
      color: waffle-color.$color-rgba-dark-100;
    }
  }

  .fr-layer {
    .fr-input-line {
      input {
        @include waffle-font.font(waffle-size.$size-16, 400);
        border-radius: 4px;

        &:focus,
        &:hover {
          border: 1px solid waffle-color.$color-mint-400;
        }

        & + label {
          @include waffle-font.font(waffle-size.$size-16, 400);
          top: waffle-size.$size-24;
          color: waffle-color.$color-gray-500;
        }

        &.fr-not-empty + label {
          @include waffle-font.font(waffle-size.$size-12, 400);
          top: waffle-size.$size-4;
          color: waffle-color.$color-gray-900;
        }
      }
    }

    .fr-action-buttons {
      height: auto;

      button.fr-command {
        @include waffle-font.font(waffle-size.$size-14, 400);
        border: 1px solid waffle-color.$color-mint-600;
        border-radius: 4px;
        background-color: waffle-color.$color-mint-600;
        padding: waffle-size.$size-8 waffle-size.$size-16;
        min-width: 80px;
        height: auto;
        color: waffle-color.$color-rgba-light-100;
        font-weight: 500;

        &:hover {
          border-color: waffle-color.$color-mint-700;
          background-color: waffle-color.$color-mint-700;
          color: waffle-color.$color-rgba-light-100;
        }
      }
    }
  }

  .fr-table-size .fr-select-table-size > span.hover > span {
    border-color: waffle-color.$color-mint-500;
    background-color: waffle-color.$color-mint-200;
  }
}

.fr-command {
  &.fr-btn {
    &.fr-active + .fr-dropdown-menu {
      box-shadow: 0 0 5px rgba(10, 22, 70, 0.06), 0 6px 6px -1px rgba(10, 22, 70, 0.1);
    }

    & + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
      li {
        text-align: center;
      }

      ul.fr-dropdown-list {
        li {
          > a.fr-command:not(.fr-title) {
            @include waffle-font.font(waffle-size.$size-14, 400);
            padding: waffle-size.$size-8 waffle-size.$size-16;
            color: waffle-color.$color-gray-700;

            &.fr-disabled {
              color: waffle-color.$color-gray-500;
            }
          }
        }
      }

      .fr-command.fr-title {
        @extend %button;
        margin: 0;
        padding: waffle-size.$size-4 0;
        width: 100%;

        i svg {
          margin: 0;
        }
      }
    }
  }

  i {
    font-style: normal;
  }
}
