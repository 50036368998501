@use '../styles/common/base' as waffle-base;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/mixins/common.utils' as waffle-common;
@use '../styles/common/size' as waffle-size;

@import url('https://static.wadiz.kr/vendor/froala-editor/4.0.15/froala_style.min.css');

.fr-element,
.fr-view {
  word-break: keep-all;
  font-family: waffle-base.$font-family;
  overflow-wrap: break-word;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    &::after {
      @include waffle-common.clearfix;
    }
  }

  h1 {
    @include waffle-font.font(waffle-size.$size-48, 700);
    margin-top: calc(#{waffle-size.$size-40} + #{waffle-size.$size-8});
    margin-bottom: waffle-size.$size-16;
  }

  h2 {
    @include waffle-font.font(waffle-size.$size-36, 700);
    margin-top: calc(#{waffle-size.$size-40} + #{waffle-size.$size-8});
    margin-bottom: waffle-size.$size-16;
  }

  h3 {
    @include waffle-font.font(waffle-size.$size-28, 700);
    margin-top: waffle-size.$size-40;
    margin-bottom: waffle-size.$size-16;
  }

  h4 {
    @include waffle-font.font(waffle-size.$size-24, 700);
    margin-top: waffle-size.$size-32;
    margin-bottom: waffle-size.$size-16;
  }

  /* 레거시 사진 주석 */
  h5 {
    @include waffle-font.font(waffle-size.$size-14, 700);
    margin-bottom: waffle-size.$size-40;
    text-align: center;
    color: waffle-color.$color-gray-500;
  }

  p {
    @include waffle-font.font(waffle-size.$size-16, 400);
    margin-top: waffle-size.$size-12;
    margin-bottom: waffle-size.$size-12;
  }

  u {
    border-bottom: 0.1em solid waffle-color.$color-mint-600;
    text-decoration: none;
  }

  a {
    transition: color 0.3s;
    color: waffle-color.$color-blue-600;

    &:hover {
      text-decoration: underline;
      color: waffle-color.$color-blue-800;
    }
  }

  b,
  strong {
    font-weight: 700 !important;
  }

  hr {
    margin: waffle-size.$size-40 auto;
    border: 1px solid waffle-color.$color-gray-200;
    width: 240px;
    text-align: center;
  }

  ol {
    list-style: decimal;

    > li {
      @include waffle-font.font(waffle-size.$size-16, 400);
      position: relative;
      margin-left: calc(#{waffle-size.$size-4} * 5);
      list-style: inherit;
    }
  }

  ul {
    padding-left: 0;
    list-style: disc;

    > li {
      @include waffle-font.font(waffle-size.$size-16, 400);
      margin-left: calc(#{waffle-size.$size-4} * 5);
      list-style: inherit;

      > ul {
        list-style: circle;

        > li {
          > ul {
            list-style: circle;
          }
        }
      }
    }
  }

  table {
    margin: waffle-size.$size-24 0;
    border: none;
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    empty-cells: show;
    color: waffle-color.$color-gray-700;

    thead {
      th {
        background-color: waffle-color.$color-gray-100;
        font-weight: 700;

        &.fr-selected-cell {
          border-color: waffle-color.$color-mint-700;
        }
      }
    }

    tbody {
      > tr {
        background-color: waffle-color.$color-rgba-light-100;
      }
    }

    th,
    td {
      @include waffle-font.font(waffle-size.$size-16, 400);
      border: 1px solid waffle-color.$color-gray-300;
      padding: waffle-size.$size-8;

      img {
        margin: auto;
        padding: 0;
        width: auto !important;
        min-width: auto !important;
        height: auto !important;
      }

      &:empty {
        height: 24px;
      }
    }

    td {
      &.head-cell {
        font-weight: 700;
      }

      &.fr-highlighted, th.fr-highlighted {
        border: 1px double waffle-color.$color-red-600;
      }

      &.fr-selected-cell {
        border-color: waffle-color.$color-mint-700;
      }

      &.fr-thick, th.fr-thick {
        border-width: 2px;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      p {
        margin: 0;
      }
    }

    &.fr-dashed-borders {
      th,
      td {
        border-style: dashed;
        padding: waffle-size.$size-4 waffle-size.$size-12;
      }
    }
  }

  mark {
    background-color: transparent;
    color: inherit;

    &.color-mint {
      background-image: linear-gradient(to top, waffle-color.$color-mint-300 40%, transparent 10%, transparent 50%);
    }

    &.color-yellow {
      background-image: linear-gradient(to top, waffle-color.$color-yellow-300 40%, transparent 10%, transparent 50%);
    }
  }

  blockquote {
    @include waffle-font.font(waffle-size.$size-16, 400);
    border-left: 0.2em solid waffle-color.$color-gray-500;
    padding-left: waffle-size.$size-16;
    color: waffle-color.$color-gray-700;

    &.type-default {
      margin: 0 auto;
      border-left: none;
      padding-left: 0;
      width: 100%;
      max-width: 480px;
      text-align: center;
      color: inherit;

      &::before,
      &::after {
        display: block;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        width: 24px;
        height: 24px;
        content: '';
      }

      &::before {
        margin: waffle-size.$size-40 auto waffle-size.$size-12;
        background-image: url('../assets/images/icon-quote-before.svg');
      }

      &::after {
        margin: waffle-size.$size-12 auto waffle-size.$size-40;
        background-image: url('../assets/images/icon-quote-after.svg');
      }

      p {
        @include waffle-font.font(waffle-size.$size-24, 700);
        text-align: center;
        color: waffle-color.$color-gray-700;
        font-weight: 400;
      }
    }

    &.type-line {
      margin: waffle-size.$size-12 0;
      border-left: none;
      padding-left: 0;
      color: inherit;

      > * {
        border-left: 0.2em solid waffle-color.$color-gray-500;
        padding-left: waffle-size.$size-16;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }

      p {
        color: waffle-color.$color-gray-600;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }
  }

  img {
    max-width: 100%;
    vertical-align: top;

    &.fr-dib {
      display: block;
      margin: auto;
    }

    &.fr-dii {
      vertical-align: top;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.fr-fil,
      &.fr-fir {
        float: none !important;
      }
    }
  }

  span.fr-img-caption {
    &.fr-dii {
      vertical-align: top;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .fr-img-wrap {
      .fr-inner {
        @include waffle-font.font(waffle-size.$size-14, 700);
        color: waffle-color.$color-gray-500;
      }
    }
  }

  span.fr-video {
    &.fr-dvi {
      vertical-align: top;
    }

    iframe {
      max-width: 100%;
    }
  }
}
