@import '../../containers/MainContainer/MainContainer.module.scss';

.list_preview {
  & > div {
    position: relative;
    width: 100%;
    display: block;

    .previewCopy {
      strong,
      small {
        // -webkit-line-clamp: 1;
        @include clamp;
        -webkit-box-orient: vertical;
      }
    }

    &.previewTypeImg {
      & > div {
        height: 175px;

        position: relative;
      }

      .previewCopy {
        padding: 36px 14px 0 30px;
        max-width: 240px;
        @include absoluteCenter;
        right: auto;
        color: #fff;

        background: rgba(0, 0, 0, 0.6);

        @media screen and (max-width: $break-mid) {
          max-width: 184px;
        }

        strong {
          @include font19;
          font-weight: 500;
          line-height: 28px;
        }

        small {
          line-height: 22px;
          height: 22px;
        }

        .previewBg {
          display: flex;
          align-items: center;
          margin-top: 14px;
          height: 28px;
          font-size: 12px;
          color: #fff;
          font-weight: bold;
          width: 58px;
          justify-content: center;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      .list_previewDel {
        top: 10px;
        width: 24px;
        height: 24px;
        font-size: 20px;
        color: #fff;
        padding: 0 !important;
        right: 10px;
        background: rgba(0, 0, 0, 0.6);
        position: absolute;

        span {
          display: none;
        }

        i {
          margin-right: 0;
        }
      }

      .previewPop {
        margin: 10px 0 0;
      }

      .invisible {
        visibility: hidden;
      }
    }

    &.previewTypeKwd {
      border: solid 1px rgba(0, 0, 0, 0.06);
      height: 175px;

      .previewCopy {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        background-color: #f0f2f5;
        @include absoluteCenter;
        width: 279px;
        height: 40px;
        border-radius: 20px;

        i {
          font-size: 24px;
        }

        strong {
          margin-left: 8px;
        }
      }
    }
  }
}
