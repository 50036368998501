.container {
  position: relative;
  width: 100%;
}

.antCloseButton {
  position: absolute;
  top: -8px;
  right: 8px;

  &[class~='ant-btn']:hover {
    background-color: #fff;
  }
}

.antButton {
  &[class~='ant-btn'] {
    font-size: 17px;
    line-height: 100%;
    background-color: #90949c;
    border-color: #90949c;
    span {
      color: #fff;
    }
  }
  &[class~='ant-btn']:hover,
  &[class~='ant-btn']:focus {
    font-size: 17px;
    line-height: 100%;
    background-color: #60656a;
    border-color: #60656a;
    span {
      color: #fff;
    }
  }
}

.previewTitle {
  font-size: 28px !important; // 어드민에 경우 상위 css때문에 이 부분이 무시되고 있다. 구조를 바꾸기는 힘들므로 important를 쓴다.. ㅠㅠ
  min-height: 36px;
  line-height: 140%;
}
