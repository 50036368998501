@use '@common/waffle-styled/color';

.container {
  height: 48px;
  padding: 0;

  line-height: 47px; // height - 1px

  background-color: color.$color-gray-000;
  border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.menu {
  display: flex;
  justify-content: center;
  background-color: color.$color-gray-000;

  :global {
    .ant-menu-item {
      color: rgb(0 0 0 / 38%);
    }

    .ant-menu-item-active {
      color: color.$color-mint-400 !important;

      &::after {
        border-bottom: 2px dashed color.$color-mint-400 !important;
      }
    }

    .ant-menu-item-selected {
      color: color.$color-mint-600 !important;

      &::after {
        border-bottom: 4px solid color.$color-mint-600 !important;
      }
    }
  }
}
