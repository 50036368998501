.currencyCell {
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: 20px;
  div {
    display: block;
    position: relative;
    white-space: nowrap;
    overflow: hidden;
  }
}

.numericCell {
  display: block;
  position: absolute;
  top: calc(50% - 10px);
  right: 24px;
}

.projectCell,
.areaCell,
.periodCell,
.verticalMiddleCell {
  vertical-align: middle;
}
