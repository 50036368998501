.container {
  position: relative;
  width: 100%;
  height: auto;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  img {
    display: block;
    margin: 8px auto;
    width: 80%;
  }
  margin-bottom: 40px;
}
