.container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  position: relative;
  padding: 4px;
}

.searchBox {
  position: relative;
  display: block;
}

.treeContainer {
  height: calc(100% - 160px);
  margin-bottom: 160px;
  overflow: hidden;
}

.loadContainer {
  margin: 32px 32px;
  text-align: center;
}
