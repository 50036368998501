@import '../AdModifyContent.module.scss';

em.helper {
  font-size: 13px;
  color: $color-grey;
  display: block;
  width: 100%;
  margin: 10px 0 0;
}

.modify_ad {
  section {
    margin-bottom: 16px;
  }
}

