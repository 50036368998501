@use '../styles/common/size' as waffle-size;
@use '../styles/common/font' as waffle-font;
@use '../styles/common/color' as waffle-color;
@use '../styles/common/zindex' as waffle-zindex;
@use '../styles/common/mixins/breakpoints.utils' as waffle-breakpoint;

@keyframes overlayInit {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.2)
  }
}


.confirmModal {
  display: inline-flex;
  position: relative;
  flex-direction: column;
  outline: none;
  border-radius: 16px;
  box-shadow: 0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 5px 0 rgba(10, 22, 70, 0.06);
  background: waffle-color.$color-rgba-light-100;
  padding: waffle-size.$size-24 0;
  max-height: calc(100vh - 160px);
  overflow: hidden;
  -webkit-overflow-scrolling: touch;

  .header {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    gap: waffle-size.$size-12;

    &.emptyTitle:not(:empty) {
      padding-bottom: waffle-size.$size-8;
    }

    &.border {
      border-bottom: 1px solid waffle-color.$color-gray-200;
      padding-bottom: waffle-size.$size-16;
    }
  }

  .closeIconRoot {
    flex-shrink: 0;
    margin-left: auto;
  }

  .floating {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: 0;
    background: none;
    cursor: pointer;
    padding: waffle-size.$size-24;
  }

  .title {
    @include waffle-font.font(waffle-size.$size-18, 700);
    color: waffle-color.$color-gray-900;
    word-break: keep-all;
    overflow-wrap: break-word;
  }

  .content {
    display: inline-flex;
    flex-direction: column;
    overflow: auto;
    color: waffle-color.$color-gray-700;

    &.hasTitle {
      margin-top: waffle-size.$size-12;
    }

    &.fullHeight {
      height: 100%;
    }

    .message {
      @include waffle-font.font(waffle-size.$size-16, 400);
      border: 0;
      width: 100%;
      resize: none;
    }
  }

  .footer {
    position: relative;
    margin-top: waffle-size.$size-24;

    .buttonGroup {
      display: inline-flex;
      justify-content: flex-end;
      width: 100%;

      .negativeButton {
        margin-right: waffle-size.$size-8;
      }

      .positiveButton {
        margin-right: 0;
      }
    }

    .buttonGroupVertical {
      flex-direction: column;
      flex-flow: column-reverse;

      .negativeButton {
        margin-top: waffle-size.$size-12;
        margin-right: 0;
      }
    }
  }
}

.overlay {
  display: inline-flex;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  z-index: waffle-zindex.$z-32;
  background-color: transparent;
  overflow: hidden;
  animation: overlayInit 0.25s;
}

.sizeMobile,
.sizeSm,
.sizeMd,
.sizeLg,
.sizeXl {
  max-width: calc(100% - 32px);
}

.sizeMobile,
.sizeSm {
  width: 400px;
}

.sizeMd {
  width: 560px;

  .title {
    @include waffle-font.font(waffle-size.$size-24, 700);
  }
}

.sizeLg {
  width: 640px;

  .title {
    @include waffle-font.font(waffle-size.$size-28, 700);
  }
}

.sizeXl {
  width: 840px;

  .title {
    @include waffle-font.font(waffle-size.$size-32, 700);
  }

  .content {
    margin-top: waffle-size.$size-24;
  }
}

.sizeFull {
  border-radius: 0;
  padding-top: waffle-size.$size-24;
  width: 100%;
  height: 100%;
  max-height: 100vh;

  .title {
    @include waffle-font.font(waffle-size.$size-24, 700);
  }
}

.sizeSheet, .sizeSheetMD {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px 16px 0 0;
  padding-top: 0;
  padding-bottom: calc(24px + env(safe-area-inset-bottom));
  max-height: 90%;
  overflow: visible;

  .header {
    padding-top: waffle-size.$size-24;
  }

  .footer {
    .buttonGroup {
      display: flex;

      &:not(.buttonGroupVertical) {
        margin: 0 -4px;
        width: auto;

        button {
          margin: 0 waffle-size.$size-4;
        }
      }

      .negativeButton {
        margin: 0;
      }

      button {
        flex: 1;
      }
    }

    .buttonGroupVertical {
      .negativeButton {
        margin-top: waffle-size.$size-8;
      }

      button {
        flex: unset;
      }
    }
  }
}

.sizeSheet,
.sizeSheetMD,
.sizeFull {
  transform: translateY(55%);
  transition: 150ms ease-out 50ms;
  transition-property: transform;

  &.contentAfterOpen {
    transform: translateY(0);
    transition-timing-function: ease-in;
    transition-delay: 0ms;

    >.container {
      @include waffle-breakpoint.breakpoint-desktop {
        transform: translateY(0);
        opacity: 1;
      }
    }
  }

  &.contentBeforeClose {
    transform: translateY(105%);

    @include waffle-breakpoint.breakpoint-desktop {
      transform: translateY(0);
    }

    >.container {
      @include waffle-breakpoint.breakpoint-desktop {
        transform: translateY(5%);
        opacity: 0;
      }
    }
  }
}

.sizeSheetMD {
  .title {
    @include waffle-font.font(waffle-size.$size-24, 700);
  }
}

.slideDown {
  transform: translateY(100%);
}

.overlayTransparent {
  box-shadow: 0 -16px 16px -1px rgba(10, 22, 70, 0.1), 0 0 5px 0 rgba(10, 22, 70, 0.06);
}
