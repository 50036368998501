.imageContainer {
  display: block;
  overflow: hidden;
  position: absolute;
  inset: 0;
  border-radius: 8px;
  background-color: #f2f4f6;
  color: #cdd3d8;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.64));
    width: 100%;
    height: 100%;
    content: '';
  }
}

.contents {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.textWrapper {
  position: absolute;
  bottom: 58px;
  color: #fff;
  width: calc(100% - 64px);
  margin: 0 32px;
  min-height: 69px;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
}

.title {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 100%;
}

.description {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 21px;
}

.indicator {
  position: absolute;
  width: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 0;
  margin: 24px auto;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.3);
  height: 2px;

  & > div {
    transition: transform 0.15s linear;
    transition: width 0.2s;
    background: #fff;
    width: 40%;
    height: 100%;
  }
}

.navigationButtonWrapper {
  position: absolute;
  top: calc(50% - 28px);

  &.left {
    left: 0;
  }
  &.right {
    right: 0;
  }

  .button {
    width: 32px;
    height: 56px;
    border: none;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0;
    color: #fff;

    > * {
      pointer-events: none;
    }

    svg {
      margin: 16px 0;
      width: 24px;
      height: 24px;
    }
  }
}

.adBadge {
  position: absolute;
  top: 8px;
  right: 8px;
  z-index: 24;
  border: 1px solid #fff;
  border-radius: 9px;
  background-color: rgba(0, 0, 0, 0.08);
  width: 27px;
  height: 18px;
  text-align: center;
  line-height: 18px;
  color: #fff;
  font-size: 10px;
}
