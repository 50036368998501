@import '../../containers/MainContainer/MainContainer.module.scss';

.navbar {
  display: flex;
  height: 18px;
  margin-bottom: 22px;
  align-items: center;
  width: 100%;

  ul.navbar_list {
    li.navbar_item {
      display: inline-block;
      font-size: 13px;

      &>a {
        color: $color-grey;

        &.active {
          color: #1d2129;

          &+i {
            display: none
          }
        }
      }

      i {
        margin: 0 4px;
        font-size: 14px;
        color: $color-grey;
        vertical-align: top;
      }
    }
  }

  @media screen and (max-width:$break-mid) {
    display: none;
  }
}
