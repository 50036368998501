$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;

.container {
  display: flex;
  flex-direction:row-reverse;
  align-items: center;
  background-color: #B9EEEE;
  text-align: left;

  @media screen and (max-width: $break-mid) {
    flex-direction: column-reverse;
  }

  .title {
    padding: 0 60px;
    width: 50%;

    [class*='title'] {
      max-width: 486px;

      + p {
        max-width: 503px;
      }
    }

    @media screen and (max-width: $break-mid) {
      padding: 60px 16px;
      width: 100%;
    }
  }

  .visual {
    background-image: url($assetUrl + 'displayAdVisual@2x.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 50%;
    height: 480px;

    @media screen and (max-width: $break-mid) {
      width: 100%;
      background-image: url($assetUrl + 'displayAdVisual@3x.png');
    }
  }
}
