$break-mid: 768px;

.goodsContainer {
  display: flex;
  align-items: center;
  padding: 8px;

  @media screen and (max-width: $break-mid) {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .accountList {
    display: flex;
    flex-direction: column;
    padding-right: 64px;
  }

  .carousel {
    margin-right: 16px;
  }
  .carousel,
  img {
    width: 140px;
    height: 240px;
  }
  .subImageContainer {
    position: relative;
  }
  .subText {
    text-align: center;
    white-space: pre;
    width: 210px;
    position: absolute;
    left: -25%;
  }
  .carouselText {
    text-align: center;
    display: block;
  }
  .goodsBtn {
    border: 1px solid #e9ecef;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 4px;

    &:hover {
      border: 1px solid #00b2b2;
    }
    &.active {
      font-weight: bold;
    }
    &.disabled {
      background-color: #f2f4f6;
    }
  }
}
