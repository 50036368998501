.Container {
  position: relative;
  padding: 10px 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.Title {
  line-height: 28px;
  letter-spacing: -0.6px;
  font-size: 18px;
  font-weight: 700;
}
.ThumbnailWrapper {
  position: relative;

  padding-top: 34.44%;
  border-radius: 4px;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.Thumbnail {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition-property: opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 0.25s;
  background: no-repeat 50% / cover border-box;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 10px;
}

.badge {
  line-height: 14px;
  letter-spacing: 0;
  font-size: 10px;
  font-weight: 400;
  padding: 3px 8px;
  display: flex;
  position: absolute;
  align-items: center;
  right: 16px;
  bottom: 16px;
  opacity: 0.7;
  border-radius: 4px;
  background-color: #f9fafb;
  color: #212529;
}
