.radio {
  border: 1px solid #e9ecef;
  width: 100%;
  padding: 8px 12px;
  border-radius: 8px;
  margin-bottom: 4px;

  &:hover {
    border: 1px solid #00b2b2;
  }
  &.active {
    font-weight: bold;
  }
  &.disabled {
    border: 1px solid #e9ecef;
  }
}
