$break-mid: 768px;

.container {
  padding: 0 40px;

  @media screen and (max-width: $break-mid) {
    padding: 0;
  }

  li {
    list-style-type: disc;
  }
}
.adGuide {
  padding: 40px;

  @media screen and (max-width: $break-mid) {
    padding: 40px 0;
  }
}
.goodsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (max-width: $break-mid) {
    flex-direction: column-reverse;
    gap: 50px;
  }
  .accountList {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 64px;

    @media screen and (max-width: $break-mid) {
      padding-right: 0;
    }
  }

  .carousel,
  img {
    width: 140px;
    height: 240px;
  }
  .subImageContainer {
    position: relative;
  }
  .subText {
    text-align: center;
    white-space: pre;
    width: 210px;
    position: absolute;
    left: -25%;
  }
  .carouselText {
    text-align: center;
    display: block;
  }
  .goodsBtn {
    border: 1px solid #e9ecef;
    padding: 8px 12px;
    border-radius: 8px;
    margin-bottom: 4px;

    &:hover {
      border: 1px solid #00b2b2;
    }
    &.active {
      font-weight: bold;
    }
    &.disabled {
      background-color: #f2f4f6;
    }
  }
}

.selectArea {
  padding: 40px;

  @media screen and (max-width: $break-mid) {
    padding: 40px 0;
  }

  .goodsArea {
    margin-bottom: 24px;
  }
}

.hidden {
  display: none;
}

// 레거시 광고 센터에서 전역으로 설정한 값 때문에
// 클론 center가 생기기 전 까진 독립적으로 css 적용
:global {
  .ant-btn {
    line-height: normal;
    height: 32px;
  }
  // 버튼 컬러들
  .ant-btn-primary:not(:disabled):hover {
    border-color: #00b2b2;
    background-color: #00b2b2;
  }
  .ant-modal-close-x {
    width: auto;
    height: auto;
  }
  // 달력 커스텀
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    color: #fff !important;
  }
  // react-slick
  .slick-prev:before,
  .slick-next:before {
    color: black;
  }
}
