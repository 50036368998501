.itemContainer {
  color: #7c8288 !important;
  font-size: 12px;
  @media (max-width: 1095px) {
    white-space: nowrap;
  }
  .bold {
    color: rgba(0, 0, 0, 0.84);
    font-size: 19px;
    font-weight: 700;
  }
}
.sep {
  display: inline-block;
  margin: 0 8px;
  color: #e6eaed;
}
