.footerInfo {
  border-top: 1px solid #e6eaed;
  .innerBox {
    display: flex;
    position: relative;
    margin: 0 auto;
    padding-right: 80px;
    padding-left: 80px;
    max-width: 1440px;
    .infoSection {
      box-sizing: content-box;
      margin-bottom: 0;
      padding-top: 32px;
      padding-bottom: 32px;
      padding-right: 80px;

      &.secondSection {
        width: calc(50% - 90px);
        max-width: 364px;
        .infoList {
          flex-wrap: wrap;
        }
      }
      &.thirdSection {
        position: relative;
        margin-top: 8px;
        margin-bottom: 0px;
        padding-right: 0px;
        .appLink {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-top: 0;
          width: auto;
          white-space: nowrap;

          a {
            display: inline-block;
            word-break: keep-all;
            white-space: nowrap;
            margin-right: 18px;
            line-height: 24px;
            font-size: 12px;
            color: #212121;
            &:hover {
              color: #000;
              transition: color .2s;
            }
            i {
              margin-right: 8px;
              font-size: 20px;
              vertical-align: bottom;
            }
          }
        }
      }
      .infoTitle {
        display: block;
        color: rgba(0,0,0,.84);
        font-weight: 700;
        font-size: 12px;
      }
      .infoList {
        display: flex;
        align-items: baseline;
        .infoItem {
          color: #7c8288 !important;
          font-size: 12px;
          .bold {
            color: rgba(0,0,0,.84);
            font-size: 19px;
            font-weight: 700;
          }
          .sep {
            display: inline-block;
            margin: 0 8px;
            color: #e6eaed;
          }
        }
      }
      .copyright {
        margin-top: 4px;
        color: rgba(0,0,0,.84);
        font-size: 12px;
        font-weight: 700;
      }
    }
  }
}

@media ( max-width: 1095px ) {
  .footerInfo {
    .innerBox {
      display: block;
      padding-top: 24px;
      padding-right: 16px;
      padding-bottom: 32px;
      padding-left: 16px;
      .infoSection {
        margin-bottom: 16px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;

        &.secondSection {
          width: 100%;
          max-width: 100%;
          margin-bottom: 16px;
          .copyright {
            margin-top: 16px;
          }
        }
        &.thirdSection {
          margin-top: 8px;
          .appLink {
            position: static;
            top: 0;
            transform: translateY(0%);
          }
        }
      }
    }
  }
}
