@import '../../containers/MainContainer/MainContainer.module.scss';

.paginationDsg {
  float: left !important;

  &>button {
    outline: none;
    padding: 0 !important;

    i {
      margin: 0;

      &::before {
        vertical-align: middle !important;
      }
    }
  }

  li {

    &:hover,
    &.active button {
      color: rgb(0, 178, 178);
      background-color: rgba(57, 143, 230, 0.3);
    }

    &.active button {
      font-weight: bold;
    }
  }
  @media screen and (max-width:$break-mid) {
    width: 100%;
    text-align: center;
    margin-bottom:36px;
  }
}
