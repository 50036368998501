$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #E9ECEF;
  text-align: center;

  @media screen and (max-width: $break-mid) {
    padding: 60px 16px;
    gap: 24px;
  }

  .section {
    display: flex;
    width: 100%;
    max-width: 848px;
    gap: 16px;

    @media screen and (max-width:$break-mid) {
      flex-direction: column;
      width: 100%;
      max-width: 100%;
    }
  }

  .graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    padding: 24px;
    gap: 16px;
    width: 100%;
    max-width: 416px;
    height: 328px;

    > * { width: 100%; }

    @media screen and (max-width:$break-mid) {
      padding: 16px 12px;
      gap: 12px;
      width: 100%;
      max-width: 100%;
      width: 100%;
      height: auto;
    }

    .image {
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      background-color: #F8F8F8;

      @media screen and (max-width:$break-mid) {
        width: 100%;

      }
    }

    &:first-child {
      .image {
        background-image: url($assetUrl + 'graph01@2x.png');

        @media screen and (max-width:$break-mid) {
          background-image: url($assetUrl + 'graph01@3x.png');
        }
      }
    }

    &:last-child {
      .image {
        background-image: url($assetUrl + 'graph02@2x.png');

        @media screen and (max-width:$break-mid) {
          background-image: url($assetUrl + 'graph02@3x.png');
        }
      }
    }
  }

  .title {
    font-size: 14px;
    color: #212529;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
  }

  .desc {
    font-size: 10px;
    color: #ADB5BD;
    font-weight: 500;
    line-height: 14px;
    text-align: left;
  }

  section {
    margin-bottom: 0;
  }
}
