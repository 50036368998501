.container {
  width: auto !important;
}

.modalTitle {
  margin: 12px 0 0;

  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  color: rgb(0 0 0 / 84%);
  letter-spacing: normal;
}

.subTitle {
  margin: 0 0 10px;
  padding: 12px 0 0;

  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: #000;
  letter-spacing: normal;
}

.screen {
  margin: 0;
  padding: 12px;

  background-color: #f4f4f4;
  backdrop-filter: blur(17.3px);
  border-radius: 11.2px;
}

.header {
  display: flex;
  justify-content: space-between;

  font-size: 11px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: rgb(0 0 0 / 50%);
  text-align: right;
  letter-spacing: -0.27px;
}

.wadizIcon {
  width: 17px;
  height: 17px;
  margin-right: 8px;
  border-radius: 3px;
}

.mainCopy {
  margin: 8px 0;
  line-height: 1;
  color: #000;
}

.subCopy {
  color: #000;
}
