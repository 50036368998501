.title {
  font-size: 32px;
  font-weight: 700;
}
.cautionMessage {
  font-family: NotoSansKR;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #f25555;
  margin-bottom: 10px;
}
