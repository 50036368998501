@import '../../../../components/Header/Header.module.scss';
$assetUrl: '../../../../assets/images/intro/';

// 프로젝트 오픈
.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #1d2129;
  text-align: center;

  @media screen and (max-width: $break-mid) {
    padding: 80px 16px;
    gap: 0;
  }

  &::before {
    @include absoluteCenter;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.64);
    content: '';
  }

  &::after {
    @include absoluteCenter;
    transform: scaleX(-1);
    background: url($assetUrl+'project_bg.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    content: '';

    @media screen and (max-width:$break-mid) {
      transform: scaleX(1);
      background-image: url($assetUrl+'project_m_bg.jpg');
    }
  }

  p {
    z-index: 3;
    position: relative;
    color: #fff;
    font-size: 32px;
    line-height: 44px;
    font-weight: 700;

    @media screen and (max-width:$break-mid) {
      line-height: 34px;
      font-size: 24px;
    }
  }

  & > button {
    z-index: 3;
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #FFF;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
    line-height: 30px;
    cursor: pointer;

    @media screen and (max-width:$break-mid) {
      display: none;
    }
  }
}

.floatFooter {
  display: none;
  bottom: 0;
  z-index: 4;
  background: #fff;
  width: 100%;
  height: 80px;

  @media screen and (max-width:$break-mid) {
    left: 0;
    height: 56px;
  }

  &.active {
    display: block;
    position: fixed;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 1280px;
    height: 80px;

    @media screen and (max-width:$break-mid) {
      height: 56px;
    }

    strong {
      font-size: 24px;
    }

    button {
      display: block;
      margin: 0;

      @media screen and (max-width:$break-mid) {
        border-radius: 0 !important;
        width: 100%;
        height: 56px !important;
        font-size: 21px !important;
      }
    }

    .btn {
      border-color: #557cf2;
      background-color: #557cf2;
      z-index: 3;
      display: flex;
      padding: 12px 24px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #FFF;
      font-size: 20px;
      color: #fff;
      font-weight: 700;
      line-height: 30px;
      cursor: pointer;

      @media screen and (max-width:$break-mid) {
        display: none;
      }
    }
  }
}

.moBr {
  display: none;

  @media screen and (max-width: $break-mid) {
    display: block;
  }
}

.pcBr {
  display: block;

  @media screen and (max-width: $break-mid) {
    display: none;
  }
}
