$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #E9ECEF;
  text-align: center;
  counter-reset: chapter;

  @media screen and (max-width: $break-mid) {
    padding: 60px 16px;
    gap: 24px;
  }
}

.title {
  [class*='title'] {
    + p {
      @media screen and (max-width: $break-mid) {
        margin-top: 12px;
      }
    }
  }
}

.steps {
  display: flex;
  max-width: 848px;
  gap: 16px;
  align-items: center;

  @media screen and (max-width:$break-mid) {
    flex-direction: column;

    width: 100%;
    max-width: 100%;
  }
}

.steps > section {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  padding: 24px;
  gap: 16px;
  max-width: 272px;

  height: 456px;
  font-size: 16px;

  @media screen and (max-width:$break-mid) {
    width: 100%;
    max-width: 100%;
  }

  li {
    display: flex;
    position: relative;
    align-items: center;
    padding-bottom: 38px;
    height: 76px;
  }

  .contentItem {
    padding: 16px;
  }

  ol {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;

    li {
      position: relative;
      align-items: center;
      height: 76px;
      counter-increment: chapter;
      color: #495057;
      font-weight: 500;
      line-height: 28px;
      text-align: left;

      &::before {
        display: inline-block;
        z-index: 1;
        border-radius: 50%;
        background-color: #2ED5D5;
        width: 24px;
        height: 24px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        margin-right: 8px;

        content: counter(chapter);
      }

      &::after {
        display: block;
        position: absolute;
        top: 40px;
        left: 12px;
        background-color: #ADB5BD;
        width: 1px;
        height: 36px;
        content: '';
      }

      &:last-child {
        height: 36px;
        padding-bottom: 0;

        &::after {
          display: none;
        }
      }
    }
  }

  ul {
    padding: 0 18px;

    li {
      &::before {
        display: inline-block;
        margin: 10px 8px 8px 8px;
        width: 26px;
        height: 26px;
        vertical-align: sub;
        text-align: center;
        color: #00c4c3;
        font-family: wadizicon !important;
        font-size: 26px;
        font-weight: bold;
        content: '\E934';
      }
    }
  }
}

.contentNotify {
  text-align: left;

  h3 {
    display: block;
    margin: 0 0 4px;
    font-size: 18px;
    line-height: 28px;
  }

  p {
    line-height: 28px;
    font-weight: 500;
    font-size: 16px;
  }
}
