@import '../../containers/MainContainer/MainContainer.module.scss';

.ad_list_card {
  padding: 30px;
  width: calc(50% - 15px);
  margin-bottom: 30px;
  border-radius: 3px;
  align-self: stretch;
  border: solid 1px rgb(218, 220, 224);
  box-shadow: 0 20px 16px -10px rgba(0, 0, 0, 0.15);

  &-add {
    color: rgba(144, 148, 156, 0.5);
    display: flex;
    flex-direction: column;
    align-items: center;
    @include font21;
    justify-content: center;

    i {
      font-size: 48px;
      margin-bottom: 13px;
    }
  }

  @media screen and (max-width:$break-large) {
    width: 100%;
    padding: 20px;
    min-height: 560px;
  }

  dt {
    margin-bottom: 30px;

    .ad_list_status {
      float: left;

      span {
        border-radius: 2px;
        color: #fff;
        height: 25px;
        display: inline-block;
        @include font13;
        padding: 2px 10px 0;
        text-align: center;
        min-width: 52px;
        background-color: rgb(109, 114, 120);

        &.ad_list_status {
          &-ing {
            background-color: rgb(0, 178, 143);
          }

          &-hold {
            background-color: rgb(255, 129, 129);

            &+i {
              background-color: rgb(255, 102, 102);

              &:after {
                content: "!";
              }
            }
          }

          &-canceled {
            &+i {
              background-color: rgb(72, 75, 78);

              &:after {
                content: "!";
              }
            }
          }
        }
      }

      i {
        display: inline-block;
        height: 25px;
        width: 25px;
        cursor: pointer;
        text-align: center;
        border-radius: 2px;
        margin-left: 4px;
        vertical-align: bottom;

        &:after {
          color: #fff;
        }
      }
    }
    .ad_list_edit {
      float: right;

      &:after {
        @include clamp
      }

      button {
        margin-left: 10px
      }
    }

    strong {
      width: 100%;
      display: block;
      overflow: hidden;
      @include font21;
      padding-top: 10px;
    }
  }
}

.ad_list_balance,
.ad_list_price {
  @include font13;
  line-height: 140%;
  margin-bottom: 20px;

  &:after {
    @include clear
  }

  .ad_list_cost {
    float: left;
    width: 35%;
  }

  .ad_list_date {
    float: right;
    text-align: right;
    width: 65%;
  }

  strong {
    display: block;
  }
}

.ad_list_graph {
  margin-top: 40px;

  ul {
    li {
      float: left;
      width: calc(100% / 3);
      @include font13;
      text-align: center;
      color: rgb(144, 148, 156);

      strong {
        display: block;
        color: rgb(41, 41, 41);
      }
    }
  }
}

.ad_list_graph_null {
  text-align: center;
  padding: 50px 0;
  opacity: 0.5;
  color: $color-slate;
  @include font13;

  i {
    font-size: 38px;
    margin-bottom: 20px;
  }
}
