.container {
  position: relative;
  height: 233px;
}

.preview {
  position: relative;
  padding: 39px 32px;
  width: 100%;
  height: 138px;
  margin-bottom: 38px;
  border: 1px solid #ffe;

  p {
    font-family: NotoSansKR;
    font-size: 20px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #000;
  }

  img {
    position: absolute;
    right: 20px;
    top: 6px;
    height: 126px;
    width: 179px;
  }
}

.antCloseButton {
  position: absolute;
  top: -8px;
  right: 8px;

  &[class~='ant-btn']:hover {
    background-color: #fff;
  }
}

.antButton {
  &[class~='ant-btn'] {
    font-size: 17px;
    line-height: 100%;
    background-color: #90949c;
    border-color: #90949c;
    span {
      color: #fff;
    }
  }
  &[class~='ant-btn']:hover,
  &[class~='ant-btn']:focus {
    font-size: 17px;
    line-height: 100%;
    background-color: #60656a;
    border-color: #60656a;
    span {
      color: #fff;
    }
  }
}
