$assetUrl: '../../../../assets/images/intro/';
$break-mid: 768px;

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #F2F4F6;
  text-align: center;

  @media screen and (max-width: $break-mid) {
    padding: 60px 16px;
    gap: 40px;
  }

  .title {
    [class*='title'] {
      @media screen and (max-width: $break-mid) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }

  .section {
    display: flex;
    width: 100%;
    max-width: 848px;
    gap: 16px;

    @media screen and (max-width:$break-mid) {
      flex-direction: column;
      max-width: 100%;
    }
  }

  .adv {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #fff;
    padding-top: 24px;
    max-width: 416px;
    width: 100%;
    height: 602px;
    overflow: hidden;

    &:first-child {
      .visual {
        background-image: url($assetUrl + 'visualTarget@2x.png');
      }
    }

    &:last-child {
      .visual {
        background-image: url($assetUrl + 'visualPush@2x.png');
      }
    }

    @media screen and (max-width:$break-mid) {
      width: 100%;
      max-width: 100%;

      &:first-child {
        .visual {
          background-image: url($assetUrl + 'visualTarget@3x.png');
        }
      }

      &:last-child {
        .visual {
          height: 268px;
          background-image: url($assetUrl + 'visualPush@3x.png');
        }
      }
    }
  }

  .visual {
    width: 100%;
    height: 260px;
    margin-top: 24px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @media screen and (max-width:$break-mid) {
      height: 246px;
      background-size: 400px auto;
      flex-shrink: 0;
    }
  }

  .social {
    display: flex;
    gap: 4px;
    justify-content: center;

    > img {
      width: 16px;
      height: 16px;
      vertical-align: top;
    }
  }

  h4.name {
    font-size: 28px;
    color: #212529;
    line-height: 38px;
    margin-top: 16px;

    @media screen and (max-width:$break-mid) {
      margin-top: 12px;
    }
  }

  .copy {
    font-size: 14px;
    color: rgba(0,0,0,.4);
    font-weight: 700;
  }

  .button {
    display: flex;
    width: 178px;
    padding: 8px 0;
    margin-top: 16px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    border-radius: 8px;
    background-color: #343a40;
    font-size: 16px;
    color: #fff;
    font-weight: 700;
    line-height: 28px;

    > span {
      color: #fff;
    }

    @media screen and (max-width:$break-mid) {
      margin-top: 12px;
    }
  }

  hr {
    margin: 24px 16px;
    width: calc(100% - 32px);
    border: 0;
    border-top: 1px dashed #ADB5BD;

    @media screen and (max-width:$break-mid) {
      margin-left: 0;
      margin-right: 0;
      width: 100%;

    }
  }

  .desc {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    @media screen and (max-width:$break-mid) {
      letter-spacing: -.7px;
    }
  }

  .service {
    display: flex;
    padding: 0 24px;
    width: 100%;
    gap: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-top: 16px;

    span {
      display: flex;
      flex: 1 0 0;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      background-color: #F9FAFB;
      color: #868E96;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      white-space: nowrap;
    }

    @media screen and (max-width:$break-mid) {
      padding: 0 16px;
    }
  }

  .moBr {
    display: none;

    @media screen and (max-width:$break-mid) {
      display: block;
    }
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  padding: 0 24px;
  width: 481px;
  height: 86px;
  margin-top: 23px;
  text-align: center;
  border: 1px solid #343A40;
  font: {
    size: 32px;
    weight: 700;
  }
  color: #343A40;

  @media screen and (max-width: $break-mid) {
    width: 265px;
    height: 52px;
    font-size: 18px;
    margin-top: 0;
  }
}
