@import '../AdCreateContent.module.scss';

.create_ad_container_step2 {
  hgroup {
    h3:before {
      content: "2";
    }
  }

  .ad_payment {
    top: 223px;
  }
}