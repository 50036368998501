@import '../AdCreateContent/AdCreateContent.module.scss';
@import '../AdCreateContent/CreateAdStep/AccountCard/AccountCard.module.scss';

.modify_ad {
  position: relative;

  hgroup {
    margin-bottom: 40px;
  }

  .account_card {
    &[class*="cardModify"] {

      &:before,
      &:after {
        @include absoluteCenter;
      }

      &:before {
        text-align: center;
        height: 24px;
        z-index: 11;
        @include font21;
        font-weight: bold;
        color: #fff;
      }

      &:after {
        content: "";
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    &.cardModifyReviewing:before {
      content: "승인요청한 광고는 수정할 수 없습니다.";
    }

    &.cardModifyScheduled:before {
      content: "광고 예정은 수정할 수 없습니다.";
    }

    &.cardModifyCanceled:before {
      content: "취소된 광고는 수정할 수 없습니다.";
    }

    &.cardModifyAdvertising:before {
      content: "진행중 광고는 수정할 수 없습니다. ";
    }

    &.cardModifyCompleted:before {
      content: "완료된 광고는 수정할 수 없습니다. ";
    }

    &+.ad_payment {
      top: 150px;
    }
  }
}

.thad_listNull {
  text-align: center;
  width: 100%;
  max-width: 640px;
  color: rgba(107, 112, 118, .5);
  font-size: 13px;
  background: #fafbfc;
  overflow: hidden;
  padding: 160px 0 !important;

  i {
    font-size: 40px;
    display: block;
    padding-bottom: 8px;
    color: $color-slate;
    opacity: 0.5;
  }

  @media screen and (max-width:$break-mid) {
    max-width: 100%
  }
}