.container {
  position: relative;
  padding: 0;
}

.ThumbnailWrapper {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  &::before {
    position: absolute;
    bottom: 4px;
    left: 0;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.64));
    width: 100%;
    height: 100%;
    z-index: 1;
    content: '';
  }
}

.Thumbnail {
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 10px;
}

.copyWrapper {
  position: absolute;
  left: 32px;
  top: 0;
  height: calc(100% - 28px);
  z-index: 2;
}

.previewWrapper {
  position: relative;
  width: 100%;
  height: auto;
  overflow: scroll;
}

.previewPanel {
  position: relative;
  box-sizing: content-box;
  background-color: rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.64);
}

@mixin previewArea {
  position: absolute;
  top: 0;
  left: 0;
  margin: 4px;
}
.previewImage {
  @include previewArea;
}

.verticalMissingArea {
  @include previewArea;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    background-color: rgba(176, 153, 151, 0.4);
  }
}
.horizontalMissingArea {
  @include previewArea;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    background-color: rgba(150, 171, 163, 0.4);
  }
}

.copyArea {
  @include previewArea;
}

.tagArea {
  padding: 4px;
}
.previewCopyWrapper {
  position: absolute;
  box-sizing: border-box;
  border: 0 solid rgba(200, 200, 150, 0.6);
  left: 32px;
  z-index: 2;
}

.sliderContainer {
  position: relative;
  padding: 4px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 52px;
}
