@import '../../../../components/Header/Header.module.scss';

.container {
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  gap: 40px;
  align-items: center;
  background-color: #F2F4F6;
  text-align: center;

  @media screen and (max-width: $break-mid) {
    padding: 60px 16px;
    gap: 24px;
  }

  .btnAsk {
    display: flex;
    padding: 12px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    border: 1px solid #343A40;
    color: #343A40;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;

    @media screen and (max-width:$break-mid) {
      font-size: 18px;
      line-height: 28px;
    }
  }
}
