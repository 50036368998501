.Toastify__toast-icon {
  display: none !important;
}

.Toastify__toast {
  padding: 16px !important;
  border-radius: 8px !important;
}

.Toastify__toast-body {
  div:last-child {
    /* Body2・14/Regular */
    font-family: Pretendard;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
  }
}

.Toastify__toast--error,
.Toastify__toast--warning {
  border-radius: 8px;
  border: 1px solid #ffb9b9;
  background: #ffd5d5;

  color: #f25555;
}
